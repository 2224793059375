import { useNavigate, useLocation, Outlet, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import {
  Frame,
  TopBar,
  Navigation,
  Loading,
  Text,
  Link,
  Icon,
} from "@shopify/polaris";
import { RootState } from "configureStore"; // import RootState
import "./style.scss";
import { useMainMenuItems } from "pages/app/dashboard/Menu";

import {
  logout,
  toogleUserMenu,
  toogleNav,
  fetchRole,
} from "features/auth/authSlice";
import { ExitIcon, ProfileIcon } from "@shopify/polaris-icons";
import Modalx from "components/modalx/modalx";
import CompanySelect from "features/company-select/company-select.jsx";
import { useEffect, useState } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { Role } from "types/Role";

type options = {
  value: string;
  label: string;
};

function Dashboard() {
  const { companyId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [clickedItem, setClickedItem] = useState<any>(null);
  const { MainMenuItems, settingsMenu } = useMainMenuItems(companyId);
  const {
    session,
    userMenuActive,
    navActive,
    role,
  }: {
    session: { email: string; id: string } | null;
    isLoading: boolean;
    userMenuActive: boolean;
    isError: boolean;
    navActive: boolean;
    role: Role | null;
  } = useAppSelector((state: RootState) => state.authReducer);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (companyId) dispatch(fetchRole(companyId));
  }, [dispatch, companyId]);

  const { companiesCollection } = useAppSelector(
    (state: RootState) => state.companySelectReducer
  );

  const selectedCompany: options[] = companiesCollection.filter(
    (company: options) => company.value === companyId
  );

  if (!role) {
    return <Loading />;
  }

  const handleCompanyChange = (id: string) => {
    const currentPath = location.pathname;
    const parts = currentPath.split("/");
    // Assuming the company ID is the second part of the URL after '/app/'
    if (parts.length > 2 && parts.find((path) => path === "files")) {
      parts[2] = id;
      const newPath = parts.join("/");
      navigate(newPath, { replace: true });
    } else if (parts[1] === "app" && parts[2]) {
      parts[2] = id; // Replace the company ID with the new one
      // const newPath = parts.join('/');
      const parentPath = parts.slice(0, 4).join("/");
      navigate(parentPath, { replace: true });
    }
  };

  const handleLogOut = async () => {
    await dispatch(logout());
  };

  const handleToogleUserMenu = async () => {
    await dispatch(toogleUserMenu());
  };

  const handleNavigationToggle = async () => {
    await dispatch(toogleNav());
  };

  const settingsMenuNode = (
    <Navigation.Section
      title={t("menu_item_settings_title")}
      items={settingsMenu}
    />
  );

  const handleRedirect = () => {
    navigate(`/app/${companyId}/users/${session!.id}`, { replace: true });
  };

  const userMenuActions = [
    {
      items: [
        {
          content: t("view_account"),
          icon: ProfileIcon,
          onAction: handleRedirect,
        },
        { content: t("log_out"), icon: ExitIcon, onAction: handleLogOut },
      ],
    },
  ];
  const userMenuMarkup = (
    <TopBar.UserMenu
      initials=""
      actions={userMenuActions}
      name={session!.email}
      open={userMenuActive}
      onToggle={handleToogleUserMenu}
    />
  );

  const DynamicLogoSuffix = (
    <div className="logo_text">
      <Text as="h1" fontWeight="semibold">
        {selectedCompany.length > 0 ? selectedCompany[0].label : ""}
      </Text>
    </div>
  );
  const logo = {
    topBarSource: "/",
    width: 0,
    accessibilityLabel: "",
  };

  const topBarMarkup = (
    <div className="company_select_box">
      <TopBar
        showNavigationToggle
        secondaryMenu={
          <CompanySelect
            onChange={handleCompanyChange}
            isList={false}
            orgChart={false}
          />
        }
        userMenu={userMenuMarkup}
        onNavigationToggle={handleNavigationToggle}
        logoSuffix={DynamicLogoSuffix}
      />
    </div>
  );
  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={MainMenuItems.filter(
          ({ url, label, icon, id, isVisibleOnlyFor }, index) => {
            const isVisible = isVisibleOnlyFor
              ? isVisibleOnlyFor?.some((roles) =>
                  role?.permissionsgroups?.includes(roles)
                )
              : true;
            if (isVisible) {
              return { url, label, icon, id, isVisibleOnlyFor };
            } else {
              return false;
            }
          }
        )}
      />
      {role?.permissionsgroups?.includes("Master") && settingsMenuNode}
    </Navigation>
  );

  const handleClick = (index: number) => {
    setClickedItem(index === clickedItem ? null : index);
  };

  const MainHeader = () => (
    <div className="main-header">
      <ul className="main-header__menu">
        <li className="main-header__item main-header__company-select">
          <CompanySelect
            onChange={handleCompanyChange}
            isList={false}
            orgChart={false}
          />
        </li>

        {MainMenuItems.slice(0, Math.floor(MainMenuItems.length / 2)).map(
          ({ url, label, icon, id, isVisibleOnlyFor }, index) => {
            const isVisible = isVisibleOnlyFor
              ? isVisibleOnlyFor?.some((roles) =>
                  role?.permissionsgroups?.includes(roles)
                )
              : true;
            if (!isVisible) {
              return null;
            }
            return (
              <li
                key={index}
                className={`main-header__item main-header__menu-item ${
                  clickedItem === id ? "nav-active" : ""
                }`}
              >
                <Link url={url} monochrome removeUnderline={true}>
                  <div
                    className="main-header__link"
                    onClick={() => handleClick(id)}
                  >
                    <Icon source={icon} />
                    <div>{label}</div>
                  </div>
                </Link>
              </li>
            );
          }
        )}

        <li className="main-header__item main-header__logo-suffix">
          {DynamicLogoSuffix}
        </li>

        {MainMenuItems.slice(
          Math.floor(MainMenuItems.length / 2),
          MainMenuItems.length
        ).map(({ url, label, icon, id, isVisibleOnlyFor }, index) => {
          const isVisible = isVisibleOnlyFor
            ? isVisibleOnlyFor?.some((roles) =>
                role?.permissionsgroups?.includes(roles)
              )
            : true;

          if (!isVisible) {
            return null;
          }
          return (
            <li
              key={index}
              className={`main-header__item main-header__menu-item ${
                clickedItem === id ? "nav-active" : ""
              }`}
            >
              <Link url={url} monochrome removeUnderline={true}>
                <div
                  className="main-header__link"
                  onClick={() => handleClick(id)}
                >
                  <Icon source={icon} />
                  <div>{label}</div>
                </div>
              </Link>
            </li>
          );
        })}

        <li className="main-header__item main-header__user-menu">
          {userMenuMarkup}
        </li>
      </ul>
    </div>
  );

  const SubHeader: React.FC = () => {
    return role?.permissionsgroups?.includes("Master") && clickedItem === 8 ? (
      <div className="sub-header">
        <ul className="sub-header__menu">
          <div className="sub-header__settings">
            {settingsMenu.map(({ label, url }, index) => (
              <li key={index} className="sub-header__menu-item">
                <span className="sub-header__link">
                  <Link url={url} removeUnderline={true} monochrome>
                    {label}
                  </Link>
                </span>
              </li>
            ))}
          </div>
        </ul>
      </div>
    ) : null;
  };

  return (
    <>
      {isMobile && (
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={navActive}
          onNavigationDismiss={handleNavigationToggle}
          logo={logo}
        >
          <Outlet />
          <Modalx />
        </Frame>
      )}

      {isBrowser && (
        <>
          <MainHeader />
          <SubHeader />
          <Outlet />
          <Modalx />
        </>
      )}
    </>
  );
}

export default Dashboard;
