import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { setIsModalxOpen } from "components/modalx/modalx.slice";
import { post } from "../../services/customers.service";
import { fetchCustomers } from "../customers/customers.slice";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
  successMessage: null,
};
const customerFormSlice = createSlice({
  name: "customerForm",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.successMessage = action.payload;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = customerFormSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { companyId } = data;
    const result = await post(companyId, data);
    dispatch(setIsModalxOpen(false));
    dispatch(fetchCustomers(companyId));
    dispatch(setSuccessfullySaved("saved"));

    const id =
      _.get(result, "createdCustomer.id") ||
      _.get(result, "updatedCustomer.id");
    if (id) {
      return id;
    }
  } catch (e) {
    dispatch(setError("not_saved"));
    return false;
  }
};
