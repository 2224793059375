import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Page,
} from "@shopify/polaris";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { submit } from "./customer-form.slice";
import UserSelect from "features/user-select/user-select";
import "./style.scss";
import { validateEmail } from "common/validator";

function CustomerForm({ onSubmit, customer }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { isLoading, errMsg, successMessage } = useSelector(
    (state) => state.customerFormReducer
  );
  const { role } = useSelector((state) => state.authReducer);

  const [customerId, setCustomerId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [vatCode, setVatCode] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [address, setAddress] = useState("");
  const [maxAllowedDebt, setMaxAllowedDebt] = useState("");
  const [bankIban, setBankIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [paymentDelay, setPaymentDelay] = useState("");
  const [prepaymentPercentage, setPrepaymentPercentage] = useState("");
  const [fine, setFine] = useState("");
  const [externalCode, setExternalCode] = useState("");
  const [assignedUser, setAssignedUser] = useState(role.user);

  const handleFormSubmit = async () => {
    const formData = {
      customerId,
      email,
      name,
      phone,
      contactPerson,
      vatCode,
      companyCode,
      address,
      companyId,
      maxAllowedDebt,
      bankIban,
      bankName,
      paymentDelay,
      prepaymentPercentage,
      fine,
      externalCode,
      assignedUser,
    };

    const id = await dispatch(submit(formData));
    if (onSubmit) {
      onSubmit(id);
    }
    if (id) navigate(`/app/${companyId}/customers/${id}`);
  };

  const handleNameChange = (value) => {
    setName(value);

    if (!value) {
      setNameError(t("field_is_required"));
    } else {
      setNameError("");
    }
  };

  useEffect(() => {
    if (customer) {
      setCustomerId(customer.id);
      setEmail(customer.email);
      setName(customer.name);
      setPhone(customer.phone);
      setContactPerson(customer.contactPerson);
      setVatCode(customer.vatCode);
      setCompanyCode(customer.companyCode);
      setAddress(customer.address);
      setMaxAllowedDebt(customer.maxAllowedDebt);
      setBankIban(customer.bankIban);
      setBankName(customer.bankName);
      setPaymentDelay(customer.paymentDelay);
      setPrepaymentPercentage(customer.prepaymentPercentage);
      setFine(customer.fine);
      setExternalCode(customer.externalCode);
      setAssignedUser(customer.assignedUser?.id || role.user);
    }
  }, [customer, dispatch]);

  return (
    <Page fullWidth title={t("customer")}>
      <div className="customer-form">
        <Form onSubmit={handleFormSubmit} className="customer-form_form">
          <FormLayout>
            <div className="form-warnings">
              {errMsg && (
                <div className="">
                  <Banner status="critical">{t(errMsg)}</Banner>
                </div>
              )}
              {successMessage && (
                <div className="">
                  <Banner status="info">{t(successMessage)}</Banner>
                </div>
              )}
            </div>
            <TextField
              label={t("Email")}
              id="email"
              name="email"
              value={email}
              onChange={setEmail}
              requiredIndicator={true}
            />
            <TextField
              label={t("title")}
              id="name"
              name="name"
              value={name}
              error={t(nameError)}
              onChange={handleNameChange}
              requiredIndicator={true}
            />
            <UserSelect
              onChange={setAssignedUser}
              value={assignedUser}
              label={"select_assigned_user"}
              isRequired={true}
            />
            <TextField
              label={t("phone")}
              id="phone"
              name="phone"
              value={phone}
              onChange={setPhone}
            />
            <TextField
              label={t("contact_person")}
              id="contactPerson"
              name="contactPerson"
              value={contactPerson}
              onChange={setContactPerson}
            />
            <TextField
              label={t("company_code")}
              id="companyCode"
              name="companyCode"
              value={companyCode}
              onChange={setCompanyCode}
            />
            <TextField
              label={t("vat_code")}
              id="vatCode"
              name="vatCode"
              value={vatCode}
              onChange={setVatCode}
            />
            <TextField
              label={t("address")}
              id="address"
              name="address"
              value={address}
              onChange={setAddress}
            />
            <TextField
              label={t("bank_iban")}
              id="bankIban"
              name="bankIban"
              value={bankIban}
              onChange={setBankIban}
            />
            <TextField
              label={t("bank_name")}
              id="bankName"
              name="bankName"
              value={bankName}
              onChange={setBankName}
            />
            <TextField
              label={t("external_code")}
              id="externalCode"
              name="externalCode"
              value={externalCode}
              onChange={setExternalCode}
            />
            <Button
              primary
              onClick={handleFormSubmit}
              disabled={!name || !assignedUser || !validateEmail(email)}
              loading={isLoading}
            >
              {t("save")}
            </Button>
          </FormLayout>
        </Form>
      </div>
    </Page>
  );
}

export default CustomerForm;

CustomerForm.propTypes = {
  onSubmit: PropTypes.func,
  customer: PropTypes.object,
};

CustomerForm.defaultProps = {
  onSubmit: null,
  customer: null,
};
