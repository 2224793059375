import { useState, useCallback, useEffect } from "react";
import _ from "lodash";
import { Combobox, LegacyStack, Tag, Listbox } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import permissions from "./permissions.json";

interface Permissions {
  label: string;
  value: string;
}

interface PermissionsSelectProps {
  value: string[];
  onChange: (selected: string[]) => void;
}

const PermissionsSelect: React.FC<PermissionsSelectProps> = ({
  value,
  onChange,
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState<Permissions[]>(permissions);
  const [error] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [suggestion, setSuggestion] = useState<string>("");

  useEffect(() => {
    setSelectedTags(value);
  }, [JSON.stringify(value)]);

  const handleMultiSelectChange = useCallback(
    (selected: string | undefined) => {
      if (selected) {
        const updatedSelection = selectedTags.includes(selected)
          ? selectedTags.filter((tag) => tag !== selected)
          : [...selectedTags, selected];
        setSelectedTags(updatedSelection);
        setSuggestion("");
        onChange(updatedSelection);
      }
    },
    [selectedTags, onChange]
  );

  const handleRemoveTag = useCallback(
    (tag: string) => () => {
      const updatedSelection = selectedTags.filter((item) => item !== tag);
      setSelectedTags(updatedSelection);
      onChange(updatedSelection);
    },
    [selectedTags, onChange]
  );

  const optionsMarkup = collection.map((option, index) => {
    const isSelected = selectedTags.includes(option.value);
    return (
      <Listbox.Option
        key={index}
        value={option.value}
        selected={isSelected}
        accessibilityLabel={option.label}
      >
        <Listbox.TextOption selected={isSelected}>
          {option.label}
        </Listbox.TextOption>
      </Listbox.Option>
    );
  });
  return (
    <div>
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            label={t("permissions_select")}
            suggestion={suggestion}
            inputMode="none"
            error={error}
            autoComplete="off"
            verticalContent={
              <LegacyStack spacing="extraTight">
                {selectedTags.map((tag, index) => (
                  <Tag key={index} onRemove={handleRemoveTag(tag)}>
                    {collection.find((item) => item.value === tag)?.label}
                  </Tag>
                ))}
              </LegacyStack>
            }
          />
        }
      >
        <Listbox onSelect={handleMultiSelectChange}>{optionsMarkup}</Listbox>
      </Combobox>
    </div>
  );
};

export default PermissionsSelect;
