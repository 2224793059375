import { useEffect, useCallback } from "react";
import { Page, Card } from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  fetchCustomField,
  setDestroy,
} from "containers/custom-field/custom-field-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DataTable from "components/datatable/Datatable";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";

function CustomFieldContainer() {
  const { collection } = useSelector(
    (state: RootState) => state.customFieldReducer
  );

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const fetchData = useCallback(
    (companyId: string) => {
      if (companyId) dispatch(fetchCustomField(companyId));
    },
    [dispatch]
  );

  const handleCreateCustomField = () => {
    dispatch(setModalxComponent("CustomFieldForm"));
  };

  const onRowClick = ({ id }: { id: string }) => {
    dispatch(setModalxComponent("CustomFieldForm"));
    dispatch(setModalxComponentProps({ id }));
  };

  useEffect(() => {
    if (companyId) fetchData(companyId);
    return () => {
      dispatch(setDestroy());
    };
  }, [companyId, fetchData]);

  if (!companyId || !collection) {
    return null;
  }

  return (
    <Page
      fullWidth
      primaryAction={{
        content: t("custom_field_create"),
        onAction: handleCreateCustomField,
      }}
      title={t("custom_fields")}
    >
      <Card>
        <DataTable
          collection={collection}
          columns={[
            { key: "#" },
            {
              key: "label",
              name: t("custom_field_label"),
              onClick: onRowClick,
              mobileView: "topLeft",
            },
            {
              key: "fieldType",
              name: t("custom_field_type"),
              onClick: onRowClick,
              mobileView: "topRight",
            },
            {
              key: "type",
              name: t("custom_field_object"),
              onClick: onRowClick,
              translationRequired: true,
              mobileView: "bottomLeft",
            },
            {
              key: "isRequired",
              name: t("is_required"),
              onClick: onRowClick,
              translationRequired: true,
              mobileView: "bottomRight",
            },
            {
              key: "isVisibleInList",
              name: t("is_visible_in_list"),
              onClick: onRowClick,
              translationRequired: true,
            },
          ]}
        />
      </Card>
    </Page>
  );
}

export default CustomFieldContainer;
