import { useEffect, useState } from "react";
import _ from "lodash";
import { Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchContacts } from "services/customers.service";

const CustomerContactSelect = ({
  value,
  onChange,
  isRequired = false,
  name = "",
  isDisable = false,
  label = "select_contact",
  customerId = "",
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");

  const handleChange = (selectedValue) => {
    if (isRequired && !selectedValue) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }

    onChange(selectedValue, name);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetchContacts(companyId, customerId);
        const dataCollection = _.map(data.contactsCollection, (row) => ({
          label: row.name || row.email,
          value: row.id,
        }));
        if (dataCollection.length === 1) {
          onChange(dataCollection[0].value);
        } else if (dataCollection.length > 1) {
          dataCollection.unshift({ label: " - ", value: "" });
        }
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId, customerId]);

  return (
    <div>
      <Select
        label={t(label)}
        options={collection}
        value={value}
        disabled={isLoading || isDisable}
        onChange={handleChange}
        error={error}
        requiredIndicator={isRequired ? true : false}
      />
    </div>
  );
};

export default CustomerContactSelect;
