import { useTranslation } from "react-i18next";
import {
  PersonIcon,
  OrganizationIcon,
  HomeIcon,
  ProductIcon,
  PageAttachmentIcon,
  ProductReferenceIcon,
  CollectionReferenceIcon,
  ListNumberedIcon,
  SettingsIcon,
  InfoIcon,
  TextBlockIcon,
  PersonLockIcon,
} from "@shopify/polaris-icons";

export const useMainMenuItems = (companyId) => {
  const { t } = useTranslation();

  const MainMenuItems = [
    {
      id: 1,
      url: `/app`,
      label: t("Dashboard"),
      icon: HomeIcon,
    },
    {
      id: 2,
      url: `/app/${companyId}/customers`,
      label: t("customers"),
      icon: OrganizationIcon,
      isVisibleOnlyFor: ["Master", "Admin", "SalesMan", "Signature"],
    },
    {
      id: 3,
      url: `/app/${companyId}/products`,
      label: t("products"),
      icon: ProductIcon,
      isVisibleOnlyFor: ["Master", "Admin", "SalesMan", "Signature"],
    },
    {
      id: 4,
      url: `/app/${companyId}/reservations`,
      label: t("reservations"),
      icon: ProductReferenceIcon,
      isVisibleOnlyFor: ["Master", "Admin", "SalesMan", "Signature"],
    },
    {
      id: 5,
      url: `/app/${companyId}/project-boards`,
      label: t("projects"),
      icon: PageAttachmentIcon,
      isVisibleOnlyFor: ["Master", "Admin", "SalesMan", "Signature"],
    },
    {
      id: 6,
      url: `/app/${companyId}/documents`,
      label: t("documents"),
      icon: PageAttachmentIcon,
      isVisibleOnlyFor: ["Master", "Admin"],
    },
    {
      id: 7,
      url: `/app/${companyId}/about-company`,
      label: t("about_company"),
      icon: InfoIcon,
    },
    {
      id: 8,
      url: `/app/${companyId}/settings`,
      label: t("settings"),
      icon: SettingsIcon,
      isVisibleOnlyFor: ["Master"],
    },
  ];

  const settingsMenu = [
    {
      id: 1,
      url: `/app/${companyId}/users`,
      label: t("users"),
      icon: PersonIcon,
    },
    {
      id: 2,
      url: `/app/${companyId}/files/categories`,
      label: t("files_categories"),
      icon: PageAttachmentIcon,
    },
    {
      id: 3,
      url: `/app/${companyId}/files/templates`,
      label: t("files_templates"),
      icon: PageAttachmentIcon,
    },
    {
      id: 4,
      url: `/app/${companyId}/customfields`,
      label: t("custom_fields"),
      icon: CollectionReferenceIcon,
    },
    {
      id: 5,
      url: `/app/${companyId}/filestypes`,
      label: t("files_types"),
      icon: TextBlockIcon,
    },
    {
      id: 6,
      url: `/app/${companyId}/series`,
      label: t("series"),
      icon: ListNumberedIcon,
    },
    {
      id: 7,
      url: `/app/${companyId}/permissions-groups`,
      label: t("groups_management"),
      icon: PersonLockIcon,
    },
  ];

  return { MainMenuItems, settingsMenu };
};
