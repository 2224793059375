import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { authSession, logOut, getRole } from "./auth.service";
import { Role } from "types/Role";

// Define the state type
interface AuthState {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string | null;
  session: any;
  userMenuActive: boolean;
  navActive: boolean;
  role: Role | null;
  isLoggedIn: boolean;
  language: string;
}

// Define the initial state
const initialState: AuthState = {
  isLoading: false,
  isError: false,
  errorMessage: null,
  session: null,
  userMenuActive: false,
  navActive: false,
  role: null,
  isLoggedIn: false,
  language: "lt",
};

// Create the slice
const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<{ session: any }>) {
      // Replace 'any' with the actual session type if known
      state.session = action.payload.session;
      state.isLoggedIn = true;
      state.isLoading = false;
    },
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.session = null;
      state.isError = true;
      state.isLoggedIn = false;
      state.errorMessage = action.payload;
    },
    setLogout(state) {
      state.isLoading = false;
      state.session = null;
      state.isLoggedIn = false;
      state.role = null;
    },
    setLanguage(state, action) {
      state.language = action.payload;
    },
    setUserMenuActive(state) {
      state.userMenuActive = !state.userMenuActive;
    },
    setNavActive(state) {
      state.navActive = !state.navActive;
    },
    setRole(state, action: PayloadAction<{ role: Role }>) {
      // Adjust the role type if necessary
      state.role = action.payload.role;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = AuthSlice;

// Extract and export each action creator by name
export const {
  setSession,
  setLoading,
  setError,
  setLogout,
  setUserMenuActive,
  setNavActive,
  setRole,
} = actions;
// Export the reducer, either as a default or named export
export default reducer;

export const toogleUserMenu = () => async (dispatch: any) => {
  dispatch(setUserMenuActive());
};

export const toogleNav = () => async (dispatch: any) => {
  dispatch(setNavActive());
};

export const checkSession = () => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const session = await authSession();
    dispatch(setSession({ session }));
    return true;
  } catch (e) {
    dispatch(setError("403"));
    return false;
  }
};

export const logout = () => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    await logOut();
    dispatch(setLogout());
  } catch (e: any) {
    dispatch(setError(e.message));
  }
};

export const fetchRole = (companyId: string) => async (dispatch: any) => {
  dispatch(setLoading());
  try {
    const role = await getRole(companyId);
    dispatch(setRole({ role }));
  } catch (e: any) {
    dispatch(setError(e.message));
  }
};
