import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Select,
  Spinner,
  Button,
  ResourceList,
  ResourceItem,
  Text,
  Avatar,
  Card,
} from "@shopify/polaris";
import { PlusCircleIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchCompanies } from "./company-select.slice";
import { setModalxComponent } from "components/modalx/modalx.slice";
import "./company-select.scss";
import OrgChart from "components/orgChart/orgChart";
import companyChart from "../../assets/images/company-chart.png";
import ImageMapper from "react-img-mapper";
import { coordinateJson } from "components/orgChart/orgChartData";

function CompanySelect({ onChange, isList, orgChart }) {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { companiesCollection, companiesCollectionRaw, isLoading } =
    useSelector((state) => state.companySelectReducer);

  const handleCompanyRedirect = (area) => {
    if (checkCompanyAccess(area.id)) {
      if (area.id) history(`/app/${area.id}`);
    }
  };
  const validCompnayIds = companiesCollectionRaw.reduce((acc, curr) => {
    acc[curr.id] = curr;
    return acc;
  }, {});

  const checkCompanyAccess = (id) => {
    if (validCompnayIds.hasOwnProperty(id)) {
      return true;
    }
    return false;
  };

  const handleCreateCompany = () => {
    dispatch(setModalxComponent("CompanyForm"));
  };

  const handleCompanyChange = (companyToSelect) => {
    onChange(companyToSelect);
  };

  const createButton = (
    <Button onClick={handleCreateCompany} size="slim" icon={PlusCircleIcon} />
  );

  useEffect(() => {
    if (!companiesCollection.length) {
      dispatch(fetchCompanies());
    }
  }, [dispatch, companiesCollection.length]);

  if (isLoading && companiesCollection.length === 0) {
    return <Spinner accessibilityLabel="" size="small" />;
  }

  if (companiesCollection.length === 0 && !isLoading) {
    return createButton;
  }

  const MAP = {
    name: "my-map",
    areas: coordinateJson,
  };

  const handleMouseEnter = (area) => {
    if (checkCompanyAccess(area.id)) {
      document.body.style.cursor = "pointer";
    }
  };

  const handleMouseLeave = () => {
    document.body.style.cursor = "default";
  };

  if (orgChart) {
    return (
      <div className="company_chart">
        <ImageMapper
          src={companyChart}
          map={MAP}
          onClick={handleCompanyRedirect}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </div>
    );
  }

  if (isList) {
    return (
      <Card>
        <ResourceList
          resourceName={{ singular: "customer", plural: "customers" }}
          items={companiesCollectionRaw}
          renderItem={(item) => {
            const { id, name, address } = item;
            const media = <Avatar customer size="md" name={name} />;

            return (
              <ResourceItem
                id={id}
                url={id}
                media={media}
                accessibilityLabel={`View details for ${name}`}
              >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                <div>{address}</div>
              </ResourceItem>
            );
          }}
        />
      </Card>
    );
  }

  return (
    <div className="company-select">
      <Select
        label={t("Selected company")}
        labelInline
        options={companiesCollection}
        onChange={handleCompanyChange}
        value={companyId}
        disabled={isLoading}
      />
      {/* {createButton} */}
    </div>
  );
}

export default CompanySelect;

CompanySelect.propTypes = {
  onChange: PropTypes.func,
};

CompanySelect.defaultProps = {
  onChange: () => {},
};
