import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import changeHandler from "common/form/changeHandler";
import submitHandler from "common/form/submitHandler";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Card,
  TextContainer,
  Text,
  Banner,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { setFormData, submit, update } from "./company-create.slice";
import { fetchById } from "services/company.service";

function CompanyCreate({ id }) {
  const [successMessage, setSuccessMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formControls, isLoading, isFormValid, isSuccessfullySaved } =
    useSelector((state) => state.companyCreateReducer);

  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    try {
      id
        ? await dispatch(update(id, formData))
        : await dispatch(submit(formData));
      setSuccessMessage("ok");
    } catch (e) {
      setErrMsg("error");
    }
  };

  const fetchCompanyData = useCallback(async () => {
    if (id) {
      try {
        const { companyEntity: companyData } = await fetchById(id);
        const prefilledForm = {
          ...formControls,
          name: {
            ...formControls.name,
            value: companyData.name,
            isValid: true,
          },
          legalName: {
            ...formControls.legalName,
            value: companyData.legalName,
            isValid: true,
          },
          code: {
            ...formControls.code,
            value: companyData.code,
            isValid: true,
          },
          vat: { ...formControls.vat, value: companyData.vat },
          address: {
            ...formControls.address,
            value: companyData.address,
            isValid: true,
          },
          description: {
            ...formControls.description,
            value: companyData.description,
          },
          bankName: { ...formControls.bankName, value: companyData.bankName },
          bankIban: { ...formControls.bankIban, value: companyData.bankIban },
        };

        dispatch(
          setFormData({
            validatedFormControls: prefilledForm,
            isFormValid: true,
          })
        );
      } catch (error) {}
    }
  }, [id, dispatch]);

  useEffect(() => {
    fetchCompanyData();
  }, [fetchCompanyData]);

  // if (isSuccessfullySaved) {
  //   return (
  //     <div className="company-create">
  //       <TextContainer>
  //         <Text variant="headingMd" as="h2">
  //           {t("company_created_successfully", {
  //             companyName: formControls.name.value,
  //           })}
  //         </Text>
  //       </TextContainer>
  //     </div>
  //   );
  // }

  return (
    <div className="company-create">
      <Card title={t("create_company")} sectioned>
        <Form
          className="company-create_form"
          onSubmit={submitHandler(formControls, handleFormSubmit)}
        >
          <FormLayout>
            <div className="form-warnings">
              {errMsg && (
                <div>
                  <Banner tone="critical">{t(errMsg)}</Banner>
                </div>
              )}
              {successMessage && (
                <div>
                  <Banner tone="info">{t(successMessage)}</Banner>
                </div>
              )}
            </div>
            <TextField
              label={t("company_name")}
              type="text"
              id="name"
              autoComplete="off"
              value={formControls.name.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("legal_name")}
              type="text"
              id="legalName"
              autoComplete="off"
              value={formControls.legalName.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_code")}
              type="text"
              id="code"
              autoComplete="off"
              value={formControls.code.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_vat")}
              type="text"
              id="vat"
              autoComplete="off"
              value={formControls.vat.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("bank_name")}
              type="text"
              id="bankName"
              autoComplete="off"
              value={formControls.bankName.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("bank_iban")}
              type="text"
              p
              id="bankIban"
              autoComplete="off"
              value={formControls.bankIban.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_address")}
              type="text"
              id="address"
              autoComplete="off"
              value={formControls.address.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_description")}
              type="text"
              id="description"
              autoComplete="off"
              multiline={4}
              value={formControls.description.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <Button loading={isLoading} disabled={!isFormValid} submit>
              {id ? t("save") : t("create_company")}
            </Button>
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
}

export default CompanyCreate;
