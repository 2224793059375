import { BlockStack, Button, Card, InlineGrid, Text } from "@shopify/polaris";
import { useAppDispatch } from "hooks";
import { useSelector } from "react-redux";
import { RootState } from "configureStore";
import { PlusIcon } from "@shopify/polaris-icons";
import { useParams } from "react-router-dom";
import PrettyDate from "components/format/PrettyDate";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { useTranslation } from "react-i18next";

const CustomerQuestionnaire = ({
  customerId,
  formKeyCreatedAt,
  email,
  formSignature,
}: {
  customerId: string;
  formKeyCreatedAt: string | null;
  email: string | undefined;
  formSignature: any;
  isDisabled: boolean;
}) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const { role } = useSelector((state: RootState) => state.authReducer);

  const handleConfirmQuestionnaire = () => {
    dispatch(setModalxComponent("QuestionnaireConfirmPopup"));
    dispatch(setModalxComponentProps({ companyId, customerId }));
  };

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingSm">
            {t("customer_questionnaire")}
          </Text>
          {!formKeyCreatedAt && !formSignature && email && (
            <Button
              onClick={handleConfirmQuestionnaire}
              accessibilityLabel={t("customer_questionnaire-generate")}
              icon={PlusIcon}
            >
              {t("customer_questionnaire-generate")}
            </Button>
          )}
        </InlineGrid>
        {formKeyCreatedAt && (
          <Text as="p" tone="success">
            {t("customer_questionnaire-generated")}{" "}
            <PrettyDate showTime={true} dateString={formKeyCreatedAt} />
          </Text>
        )}
        {formSignature && (
          <Text as="p" tone="success">
            {t("customer_questionnaire-signed")}
            <br /> {formSignature.signerPosition}{" "}
            {formSignature.signerFirstName} {formSignature.signerLastName}
            <br />{" "}
            <PrettyDate showTime={true} dateString={formSignature.datetime} />
            <br /> {formSignature.ip}
          </Text>
        )}
      </BlockStack>
    </Card>
  );
};

export default CustomerQuestionnaire;
