import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Login: "Login",
      Email: "El. paštas",
      Password: "Password",
      "Sign up": "Sign up",
      "Login to application": "Login to application",
      company_created_successfully: "Company {{name}} created successfully",
      create_company: "Create company",
      tasks: "Tasks",
      create_task: "Create task",
      update_task: "Update task",
      title: "Title",
      reporter: "Author",
      assigned: "Assigned",
      resolution: "Status",
      due_date: "Due date",
      select_task_from_list: "Select a task from the list",
      resolved: "Resolved",
      is_resolved: "Resolved",
      not_resolved: "Not resolved",
      description: "Description",
      select_user: "Select user",
      log_out: "Log out",
      left: "left",
      search: "Search",
      owner: "Owner",
      assignedTo: "Assigned To",
      user_created_successfully:
        "An email has been sent to your email address containing an activation link. Please click on the link to activate your account. If you do not click the link your account will remain inactive and you will not receive further emails. If you do not receive the email within a few minutes, please check your spam folder.",
      user_created_from_invitation_successfully:
        "You have successfully created account!",
      users: "Users",
      company_settings: "Company settings",
      password_restore: "Restore password",
      signup: "Create new account",
      name: "Name",
      phone: "Phone",
      restore: "Restore",
      restore_page:
        "An email has been sent to your email address containing a restore link.",
      password_restore_email: "Check Your email and click on restore link.",
      create_user: "Create user",
      update_user: "Update user",
      user_status: "Status",
      is_disabled: "Disabled",
      not_disabled: "Active",
      disabled: "Disabled",
      company_name: "Company name",
      company_code: "Company code",
      company_vat: "VAT code",
      company_address: "Address",
      company_description: "Description",
      isDisabled: "Disabled",
      Dashboard: "Dashboard",
      Accounts: "Accounts",
      user_position: "Position",
      customers: "Customer",
      create_customer: "Create customer",
      update_customer: "Update customer",
      save: "Save",
      saved: "Saved",
      not_saved: "Not saved",
      cancel: "Cancel",
      last_edit: "Last Edit",
      make_a_comment: "Make a comment",
      project_details: "Project Details",
      error: "Error",
      field_is_required: "Field is required",
      error_getting_data: "Error getting data",
      vat_code: "VAT code",
      address: "Address",
      valid_to: "Valid until",
      date_picker: "Date picker",
      contact_person: "Contact person",
      orders: "Orders",
      create_order: "Create order",
      expires: "Expires",
      date: "Date",
      number: "Code",
      order_with_provided_number_allready_exist:
        "Order with provided number allready exist",
      customer: "Customer",
      select: "Select",
      unexpected_error: "Unexpected error",
      vats: "Vats",
      create_vat: "Create Vat",
      percent: "Percent",
      products: "Products",
      create_product: "Create product",
      sku: "Sku",
      unitPrice: "Unit price",
      type: "Type",
      vat: "Vat",
      is_active: "Is active",
      product: "Product",
      service: "Service",
      inactive: "Inactive",
      bills: "Bills",
      invoices: "Invoices",
      requisites: "Requisites",
      create_invoice: "Create invoice",
      serial: "Serial",
      series: "Series",
      series_label: "Series",
      series_create: "Series-create",
      user: "User",
      order: "Order",
      quantity: "Quantity",
      unit_price: "Unit price",
      add_invoice_item: "Add invoice item",
      email_incorrect: "Email not found",
      category_not_found: "Category not found",
      user_exists: "E-mail address already registered",
      add_order_item: "Add order item",
      is_period: "Is period",
      next_invoice_date: "Next order date",
      month: "Month",
      day: "Day",
      total: "Total",
      important: "Important",
      comment: "Comment",
      documents: "Documents",
      category: "Category",
      action: "Action",
      custom_field_code: "Code",
      custom_field_label: "Label",
      custom_field_type: "Type",
      view_account: "View account",
      email_connect: "Email connect",
      status: "Status",
      not_connected: "Not connected",
      connected: "Connected",
      connect: "Connect",
      external: "External",
      internal: "Internal",
      about_company: "About Company",
      the_file_was_uploaded_by: "The file was uploaded by",
      view: "View",
      label: "Label",
      template: "Template",
      files_types_create: "Create",
      files_types: "Files Types",
    },
  },
  lt: {
    translation: {
      Login: "Prisijungti",
      "Login to application": "Prisijungti prie programos",
      Email: "El. paštas",
      Password: "Slaptažodis",
      "Sign up": "Registracija",
      "Selected company": "Pasirinkta įmonė",
      create_company: "Sukurti įmonę",
      company_created_successfully: "Įmonė sukurta sėkmingai",
      tasks: "Užduotys",
      create_task: "Sukurti užduotį",
      update_task: "Atnaujinti užduotį",
      title: "Pavadinimas",
      reporter: "Sukurė",
      assigned: "Priskirta",
      resolution: "Būsena",
      is_resolved: "Padaryta",
      not_resolved: "Nepadaryta",
      due_date: "Iki kada",
      select_task_from_list: "Pasirinkite užduoti iš sąrašo",
      resolved: "Atlikta",
      description: "Aprašymas",
      select_user: "Pasirinkitę darbuotoją",
      log_out: "Atsijungti",
      left: "liko",
      search: "Paieška",
      owner: "Autorius",
      assignedTo: "Priskirta",
      user_created_successfully:
        "Jūsų el. pašto adresu buvo išsiųstas el. laiškas su aktyvinimo nuoroda. Norėdami suaktyvinti paskyrą, spustelėkite nuorodą. Jei negaunate el. laiško per kelias minutes, patikrinkite savo šlamšto aplanką.",
      user_created_from_invitation_successfully:
        "Jūsų paskyra sėkmingai sukurta!",
      users: "Darbuotojai",
      company_settings: "Įmonės nustatymai",
      password_restore: "Slaptažodžio atkūrimas",
      signup: "Sukurti paskyrą",
      name: "Vardas",
      phone: "Telefonas",
      restore: "Atstatyti",
      restore_page:
        "Nurodytu el.paštu buvo išsiųstas el. laiškas su instrukcija",
      password_restore_email:
        "Pasitikrinkite savo pašto dėžutę ir paspauskite pateiktą nuorodą.",
      create_user: "Sukurti vartotoją",
      update_user: "Atnaujinti vartotoją",
      user_status: "Būsena",
      is_disabled: "Išjungtas",
      not_disabled: "Aktyvus",
      disabled: "Išjungtas",
      company_name: "Įmonės/veiklos pavadinimas",
      company_code: "Įm. Kodas",
      company_vat: "PVM kodas",
      company_address: "Adresas",
      company_description: "Aprašymas",
      isDisabled: "Išjungtas",
      Dashboard: "Pradžia",
      Accounts: "Paskyros",
      user_position: "Pareigos",
      customers: "Klientai",
      create_customer: "Sukurti klientą",
      update_customer: "Atnaujinti klientą",
      save: "Išsaugoti",
      saved: "Išsaugota",
      not_saved: "Neišsaugota",
      cancel: "Atšaukti",
      error: "Error",
      field_is_required: "Laukelis yra privalomas",
      error_getting_data: "Duomenis nepasiekiami",
      vat_code: "PVM kodas",
      address: "Adresas",
      valid_to: "Galioja iki",
      date_picker: "Kalendorius",
      contact_person: "Kontaktinis asmuo",
      orders: "Sutartys",
      create_order: "Sukurti sutartį",
      expires: "Galioja iki",
      date: "Data",
      number: "Numeris",
      order_with_provided_number_allready_exist:
        "Sutartis su pateiktu numeriu jau egzistuoja",
      select: "Pasirinkimas",
      unexpected_error: "Nutiko klaida",
      vats: "PVM",
      create_vat: "Sukurti PVM",
      percent: "Procentai",
      products: "Produktai",
      create_product: "Sukurti produktą",
      sku: "Sku",
      unitPrice: "Vieneto kaina",
      type: "Tipas",
      vat: "PVM",
      is_active: "Aktyvus",
      product: "Produktas",
      service: "Paslauga",
      inactive: "Neaktyvus",
      bills: "Sąskaitos",
      invoices: "Sąskaitos",
      requisites: "Rekvizitai",
      create_invoice: "Sukurti sąskaitą",
      serial: "Serija",
      series: "Serija",
      series_label: "Serija",
      series_create: "Sukurti seriją",
      user: "Darbuotojas",
      order: "Sutartis",
      quantity: "Kiekis",
      unit_price: "Vnt. kaina",
      add_invoice_item: "Pridėti prekę",
      email_incorrect: "El. paštas nerastas",
      category_not_found: "kategorija nerasta",
      user_exists: "Nurodytas el.paštas jau yra uzregistruotas.",
      add_order_item: "Pridėti prekę",
      is_period: "Siųsti periodiškai",
      next_invoice_date: "Sekančios sąskaitos data",
      month: "Mėnesis",
      day: "Diena",
      total: "Suma",
      field_company_name_help:
        "Tai gali būti įmonės, veiklos ar kitas pavadinimas.",
      field_company_name: "Veiklos pavadinimas",
      field_password_help: "Minimalus ilgis - 8 simboliai",
      field_email_help_confirm: "Bus išsiųsta patvirtinimo nuoroda",
      form_reg_page_submit_title: "Sukūrti paskyrą",
      menu_item_settings_title: "Nustatymai",
      company_form_is_vat: "PVM mokėtojas",
      company_form_is_vat_help:
        "Bus aktyvuotas funkcionalumas skirtas PVM mokėtojams.",
      login_page_sent_title: "Prisijungimas",
      login_page_sent_text: "Prisijungimo nuoroda buvo issiusta el. pastu",
      files: "Dokumentai",
      file: "Dokumentai",
      customer_details: "Informacija",
      project_details: "Projekto informacija",
      contacts: "Kontaktai",
      edit: "Redaguoti",
      no_contact_person_provided: "Kontaktinis asmuo nenurodytas",
      no_email_address_provided: "El. paštas nenorodytas",
      no_phone_number_provided: "Telefonas nenurodytas",
      no_address_provided: "Adresas nenurodytas",
      no_name_provided: "Pavadinimas nenurodytas",
      no_bank_iban_provided: "Banko IBAN nenurodytas",
      no_bank_name_provided: "Banko pavadinimas nenurodytas",
      no_payment_delay_provided: "Mokėjimo atidėjimas nenurodytas",
      no_prepayment_percentage_provided:
        "Išankstinio mokėjimo procentas nenurodytas",
      no_fine_provided: "Bauda nenurodyta",
      bank_details: "Banko duomenys",
      payment_delay: "Mokėjimo atidėjimas",
      prepayment_percentage: "Išankstinio mokėjimo procentas",
      fine: "Bauda",
      no_registration_code_provided: "Įmonės kodas nenurodytas",
      no_vat_code_provided: "PVM kodas nenurodytas",
      valid_email_address_is_required_we_will_send_authentication_link_to_this_email:
        "Į nurodyta el. paštą gausite vienkartinę prisijungimo nuorodą",
      get_auth_link: "Gauti nuorodą",
      max_allowed_debt: "Maksimali skolos suma",
      other: "Kita",
      no_max_allowed_debt_provided: "Maksimali skolos suma nenurodyta",
      assigned_user: "Priskirtas darbuotojas",
      external_code: "Išorinis kodas",
      debt: "Skola",
      no_assigned_person: "Darbuotojas nepriskirtas",
      value: "Reikšmė",
      overdue_debt: "Pradėlsta skola",
      payment_due_date: "Sumokėti iki",
      debt_invoices: "Skolos",
      total_quantity: "Kiekis",
      total_amount: "Suma",
      last_invoice_quantity: "PS kiekis",
      last_invoice_amount: "PS suma",
      last_invoice_date: "PS data",
      last_edit: "Redaguota",
      make_a_comment: "Parašyti komentarą",
      invoice_count: "Pirkta kartų",
      turnover: "Apyvarta",
      download: "Parsisiųsti",
      upload: "Įkelti",
      category: "Kategorija",
      file_category_user: "Kategorija",
      file_name: "Pavadinimas",
      no_files: "Kol kas dokumentų nėra",
      customer: "Klientas",
      files_categories: "Dokumentų kategorijos",
      files_categories_subject: "Objektas",
      files_categories_title: "Pavadinimas",
      files_categories_create: "Sukurti kategoriją",
      files_categories_subject_customer: "Klientas",
      files_templates: "Dokumentų šablonai",
      files_templates_title: "Pavadinimas",
      files_templates_category: "Kategorija",
      files_templates_create: "Sukurti šabloną",
      files_build_pdf: "Generuoti dokumentą",
      file_template_select: "Pasirinkti šabloną",
      files_template_custom_name: "Pavadinimas",
      files_template_custom_label: "Žyma šablone",
      files_template_custom_type: "Tipas",
      files_template_custom_add: "Pridėti lauką",
      files_template_custom_remove: "Pašalinti",
      files_template_custom_type_string: "Trumpas tekstas",
      files_template_custom_type_textarea: "Ilgas tekstas",
      files_template_custom_type_date: "Data",
      files_template_custom_remove_option: "Trinti variantą",
      files_template_custom_add_option: "Pridėti variantą",
      files_template_custom_required: "Būtinas laukelis",
      files_template_custom_type_select: "Pasirinkimas",
      files_template_custom_type_select_option: "Variantas",
      files_template_custom_type_select_select_an_option: "Pasirinkite reikšmę",
      files_template_custom_type_products: "Prekės",
      reservations: "Rezervacijos",
      projects: "Projektai",
      "project-boards": "Projektų grupės",
      "project-boards-create": "Sukurti projektų grupę",
      "project-board-name": "Projektų grupės pavadinimas",
      "project-board-column-create": "Sukurti projekto etapą",
      "project-board-column-name": "Etapo pavadinimas",
      "project-board-column-order": "Eiliškumas",
      "project-create": "Sukurti projektą",
      project_column: "Projekto etapas",
      "project-name": "Projekto pavadinimas",
      "project-description": "Projekto aprašymas",
      "project-order": "Prioritetas",
      project_board_column_edit: "Redaguoti",
      customer_select: "Pasirinkti klientą",
      price: "Kaina",
      totalPrice: "Bendra kaina",
      remove: "Pašalinti",
      search_products: "Prekių paieška",
      meta_data: "Meta duomenys",
      product_stock: "Prekės likučiai",
      product_reservations: "Prekės reservacijos",
      warehouse: "Sandėlis",
      customer_form: "Kliento anketa",
      "customer_form-general_info": "Bendra informacija",
      "customer_form-name": "Pirkėjo pavadinimas",
      "customer_form-name_help": "",
      "customer_form-reg_address": "Registracijos adresas",
      "customer_form-country": "Šalis",
      "customer_form-country_help": "",
      "customer_form-city": "Miestas",
      "customer_form-city_help": "",
      "customer_form-address": "Adresas",
      "customer_form-address_help": "",
      "customer_form-phone": "Telefonas",
      "customer_form-phone_help": "",
      "customer_form-email": "El. paštas",
      "customer_form-email_help": "",
      "customer_form-vatCode": "PVM kodas",
      "customer_form-vatCode_help": "",
      "customer_form-zipCode": "Pašto kodas",
      "customer_form-zipCode_help": "",
      "customer_form-office_address": "Faktinis (veiklos) adresas",
      "customer_form-office_address_help": "Nurodoma jeigu skiriasi adresas",
      "customer_form-iban": "IBAN sąskatos numeris",
      "customer_form-iban_help": "",
      "customer_form-iban2": "Kitas ne IBAN numeris",
      "customer_form-iban2_placeholder": "",
      "customer_form-iban2_help": "pildoma tik tuo atveju, jeigu nėra IBAN",
      "customer_form-bankName": "Banko pavadinimas",
      "customer_form-bankName_help": "",
      "customer_form-companyCode": "Įmonės kodas",
      "customer_form-companyCode_help": "",
      "customer_form-vatCode2": "Kitas PVM kodas",
      "customer_form-vatCode2_help": "",
      "customer_form-website": "Interneto svetainė",
      "customer_form-website_help": "",
      "customer_form-swiftCode": "SWIFT kodas",
      "customer_form-swiftCode_help": "",
      important: "Svarbu",
      comment: "Komentaras",
      "customer_form-error_heading": "Anketos nuoroda negaliojanti",
      "customer_form-error_message": "Prašome susisiekti su vadybininku",
      "customer_form-contacts": "Kontaktiniai duomenys",
      "customer_form-contact_fullName": "Kontaktinis asmuo (vardas, pavardė)",
      "customer_form-contact_fullName_help": "",
      "customer_form-contact_position": "Pareigos",
      "customer_form-contact_position_help": "",
      "customer_form-contact_phone_help": "",
      "customer_form-contact_email_help": "",
      "customer_form-contact_phone": "Telefonas",
      "customer_form-contact_email": "El. paštas",
      "customer_form-other_details": "Kita informacija",
      "customer_form-invoice_email": "El. paštas sąskaitų siuntimui",
      "customer_form-invoice_email_help": "",
      "customer_form-signature_details":
        "Vadovo arba asmens kuris pasirašys sutartį duomenys",
      "customer_form-signature_fullName": "Vardas, pavardė",
      "customer_form-signature_fullName_help": "",
      "customer_form-signature_position": "Pareigos",
      "customer_form-signature_position_help": "",
      "customer_form-signature_email": "El. paštas sutarties pasirašymui",
      "customer_form-signature_email_help": "",
      "customer_form-signature_basis_of_representation":
        "Atstovavimo pagrindas",
      "customer_form-signature_basis_of_representation_help":
        "Nurodyti įstatus arba kitą pagrindą. Jei pasirašys ne įmonės vadovas - pateikti atstovavimo pagrindą pagrindžiantį dokumentą",
      "customer_form-payments": "Atsakingas už mokėjimus",
      "customer_form-payments_fullName": "Vardas, pavardė",
      "customer_form-payments_fullName_help": "",
      "customer_form-payments_position": "Pareigos",
      "customer_form-payments_position_help": "",
      "customer_form-payments_email": "El. paštas",
      "customer_form-payments_email_help": "",
      "customer_form-payments_phone": "Telefonas",
      "customer_form-payments_phone_help": "",
      "customer_form-signer-positionAndFullName": "Pareigos, vardas, pavardė",
      "customer_form-sign": "Pasirašyti ir siųsti",
      "customer_form-name_required": "Prašome įvesti pavadinimą",
      "customer_form-companyCode_required": "Prašome įvesti įmonės kodą",
      "customer_form-phone_required": "Prašome įvesti telefono numerį",
      "customer_form-email_required": "Prašome įvesti el. pašto adresą",
      "customer_form-address_required": "Prašome įvesti adresą",
      "customer_form-city_required": "Prašome įvesti miestą",
      "customer_form-country_required": "Prašome pasirinkti šalį",
      "customer_form-zipCode_required": "Prašome įvesti pašto kodą",
      "customer_form-bankName_required": "Prašome įvesti banko pavadinimą",
      "customer_form-iban_required": "Prašome įvesti IBAN kodą",
      "customer_form-contact_fullName_required":
        "Prašome įvesti vardą ir pavardę",
      "customer_form-contact_phone_required": "Prašome įvesti telefono numerį",
      "customer_form-contact_position_required": "Prašome įvesti pareigas",
      "customer_form-contact_email_required": "Prašome įvesti el. paštą",
      "customer_form-signature_fullName_required":
        "Prašome įvesti vardą ir pavardę",
      "customer_form-signature_position_required": "Prašome įvesti pareigas",
      "customer_form-signature_email_required": "Prašome įvesti el. paštą",
      "customer_form-signature_basis_of_representation_required":
        "Prašome įvesti atstovavimo pagrindą",
      "customer_form-invoice_email_required":
        "Prašome įvesti sąskaitų faktūrų el. paštą",
      "customer_form-payments_fullName_required":
        "Prašome įvesti vardą ir pavardę",
      "customer_form-payments_phone_required": "Prašome įvesti telefono numerį",
      "customer_form-payments_position_required": "Prašome įvesti pareigas",
      "customer_form-payments_email_required": "Prašome įvesti el. paštą",
      "customer_form-signer_fullName_required":
        "Prašome įvesti pasirašančio asmens vardą ir pavardę",
      "customer_form-submit_error": "Prašome ištaisyti anketos klaidas",
      "customer_form-success_heading": "Anketa sėkmingai pateikta",
      "customer_form-success_text": "Netrukus su Jumis susisieksime",
      documents: "Dokumentai",
      action: "Veiksmas",
      customer_questionnaire: "Kliento anketa",
      "customer_form-different_contact_person":
        "Kontaktinis asmuo skiriasi, nurodyti kontaktinį asmenį",
      "customer_form-different_payments_person":
        "Už apmokėjimus atsakingas kitas asmuo, nurodyti kontaktinį asmenį",
      "customer_questionnaire-generated":
        "Kliento anketa sugeneruota ir išsiusta:",
      "customer_questionnaire-signed": "Kliento anketa pateikta ir pasirašyta:",
      "customer_form-documents": "Dokumentai",
      "customer_form-delete_confirmation":
        "Ar tikrai norite ištrinti dokumentą?",
      "customer_form-delete": "Ištrinti",
      add: "Pridėti",
      files_category_title: "Kategorijos pavadinimas",
      createdAt: "Sukūrimo data",
      dateTo: "Galioja iki",
      updatedAt: "Atnaujinta",
      contact_add: "Pridėti kontaktinį asmenį",
      "contact-name": "Vardas Pavardė",
      position: "Pareigos",
      email: "El. paštas",
      "is-authorized-to-sign": "Įgaliotas pasirašyti sutartį",
      "customer_questionnaire-generate": "Generuoti ir siųsti kliento anketą",
      custom_field_code: "Kodas",
      custom_field_label: "Pavadinimas",
      custom_field_type: "Tipas",
      custom_fields: "Papildomi laukeliai",
      custom_field_create: "Sukurti papildomą laukelį",
      view_account: "Peržiūrėti paskyrą",
      email_connect: "El. paštas prijungimas",
      status: "Būsena",
      not_connected: "Ne prijungta",
      connected: "Prijungta",
      connect: "Prijungti",
      external: "Išorinė",
      internal: "Vidinė",
      about_company: "Apie įmonę",
      select_series: "Pasirinkite seriją",
      externalNumber: "Įšorinis numeris",
      document_date: "Dokumento data",
      select_existing_customer: "Pasirinkti esamą klientą",
      save_customer: "Įšsaugoti klientą",
      customer_name: "Kliento pavadinimas",
      category_order: "Eiliškumas",
      email_messages: "El. paštas",
      legal_name: "Teisinis pavadinimas",
      is_shared_category: "Visoms įmonėms matoma kategorija",
      customer_search: "Klientų paieška",
      responsible_user: "Atsakingas darbuotojas",
      is_required: "Būtinas laukelis",
      is_visible_in_list: "Matomas sąraše",
      text: "Tekstas",
      checkbox: "Varnelė",
      custom_field_object: "Laukelio objektas",
      settings: "Nustatymai",
      the_file_was_uploaded_by: "Failą įkėlė",
      select_field: "pasirinkti lauką",
      add_Field: "pridėti lauką",
      save_field: "išsaugoti lauką",
      ok: "gerai",
      financials: "finansai",
      invoice: "sąskaita faktūra",
      no_sku_provided: "SKU nenurodytas",
      no_description_provided: "aprašymas nenurodytas",
      notes: "pastabos",
      no_legal_name_provided: "juridinis pavadinimas nenurodytas",
      no_vat_provided: "PVM kodas nenurodytas",
      no_code_provided: "kodas nenurodytas",
      custom_type_select_option: "pasirinkimo opcija",
      custom_remove_option: "pašalinti pasirinkimą",
      custom_add_option: "pridėti pasirinkimą",
      finvalda: "finvalda",
      assignedUser: "priskirtas darbuotojas",
      no_categories: "kategorijų nėra",
      document_name: "dokumento pavadinimas",
      file_name_is_required: "būtinas nurodyti failo pavadinimą",
      category_is_required: "būtina nurodyti kategoriją",
      user_is_required: "būtina nurodyti darbuotoją",
      customer_is_required: "būtina nurodyti klientą",
      files_template_name: "šablono pavadinimas",
      files_template_serial: "šablono serija",
      files_template_html: "šablono HTML",
      reserved: "rezervuota",
      client_id: "kliento ID",
      tenant_id: "tenant ID",
      secret: "slaptas raktas",
      finvaldaUser: "finvalda vartotojas",
      finvaldaPass: "finvalda slaptažodis",
      finvaldaAddress: "finvalda adresas",
      finvaldaCompanyId: "finvalda įmonės ID",
      no_external_code_provided: "nėra išorinio kodo",
      disconnect: "atsijungti",
      invoice_id: "sąskaitos ID",
      invoice_serial: "sąskaitos serija",
      invoice_number: "sąskaitos numeris",
      invoice_date: "sąskaitos data",
      invoice_user: "sąskaitos naudotojas",
      invoice_order: "sąskaitos užsakymas",
      bank_iban: "banko IBAN",
      bank_name: "banko pavadinimas",
      next_order_date: "kito užsakymo data",
      "Select assigned user": "Priskirtas darbuotojas",
      view: "Peržiūrėti",
      label: "Pavadinimas",
      template: "Šablonas",
      files_types_create: "Sukurti",
      files_types: "Dokumentų tipai",
      files_types_select: "Pasirinkite dokumento tipą",
      enter_file_context: "Įveskite objektą/kontekstą",
      groups_management: "Grupių valdymas",
      permissions: "Teisės",
      create: "Sukurti",
      permissions_select: "Teisių pasirinkimas",
      user_group: "Priskirta grupė",
      manager: "Vadovas",
      totalWithVat: "Suma su PVM",
      totalWithoutVat: "Suma be PVM",
      turnoverWithoutVat: "Apyvarta be PVM",
      debtWithVat: "Skola su PVM",
      sales_returns: "Grąžinimai",
      is_approval_required: "Reikalingas vadovo patvirtinimas",
      approval_required: "Reikalingas vadovo patvirtinimas",
      Are_you_sure_you_want_to_generate_questionnaire:
        "Ar tikrai norite generuoti ir siųsti anketą klientui?",
      save_and_approve: "Išsaugoti ir patvirtinti",
      Yes: "Taip",
      No: "Ne",
      approval_request: "Patvirtinti klientą",
      Are_you_sure_you_want_to_approve_customer:
        "Ar tikrai norite patvirtinti klientą",
      "customer_form-signer-position": "Pareigos",
      "customer_form-signer-firstName": "Vardas",
      "customer_form-signer-lastName": "Pavardė",
      "customer_form-signer_position_required": "Būtina nurodyti pareigas",
      "customer_form-signer_firstName_required": "Būtina nurodyti vardą",
      "customer_form-signer_lastName_required": "Būtina nurodyti pavardę",
      advance_invoice_deferral_days: "Avansinės sąskaitos atidėjimo dienos",
      invoice_deferral_days: "Sąskaitos atidėjimas, dienos",
      advance_payment_percent: "Avanso mokėjimo procentas",
      debt_limit: "Skolos limitas, Eur",
      penalty_percent: "Baudos procentas",
      storage_costs_per_unit: "Sandėliavimo išlaidos už vienetą",
      non_performance_percent: "Neįvykdymo procentas",
      dateTo: "Iki datos",
      Are_you_sure_you_want_to_send_document_signature_request:
        "Ar tikrai norite išsiųsti dokumento pasirašymo užklausą?",
      Are_you_sure_you_want_to_generate_and_send_finance_file_for_customer_sign:
        "Ar tikrai norite sugeneruoti ir išsiųsti pagrindinę sutartį?",
      generate_form_and_send_for_customer:
        "Sugeneruoti formą ir išsiųsti klientui",
      Finance: "Finansai",
      signed: "Pasirašyta",
      document_signature: "Dokumento pasirašymas",
      select_contact: "Pasirinkti kliento kontaktą",
      select_user: "Pasirinkti vartotoją",
      document_send_signature_request: "Siųsti pasirašymo prašymą",
      document_status: "Dokumento būsena",
      sign_status: "Pasirašymo būsena",
      contact_signer: "Kliento kontaktas",
      selected_user: "Pasirinktas vartotojas",
      customer_contact: "Kliento kontaktas",
      select_assigned_user: "Pasirinkite priskirtą vartotoją",
      is_valid_financial_approval_required:
        "Reikalingas finansinis patvirtinimas",
      signature_pending: "Laukiama parašo",
      finance_activated: "Pasirašyta",
      master_agreement: "Pagrindinė susitartis",
      master_agreement_description:
        "Pagrindinė sutartis yra dokumentas, kuriame nustatomos bendrosios sąlygos, taisyklės ir įsipareigojimai, taikomi šalims, sudarant tarpusavio sandorius.",
      master_agreement_approved: "Patvirtinta",
      master_agreement_not_approved: "Nepatvirtinta",
      Are_you_sure_you_want_to_request_to_sign_file:
        "Ar tikrai norite pateikti prašymą pasirašyti failą",
      no_manager_provided: "Vadovas nenurodytas",
      invalid_email_address: "Neteisingas el.paštas",
      sign_type: "Pasirašymo tipas",
      manual_sign: "Rankinis pasirašymas",
      upload_signed_document: "Įkelti pasirašytą dokumentą",
      sign_request: "Pasirašymo prašymas",
      select_signers: "Pasirinkite kas pasirašys",
      select_sign_permission_user: "Pasirinkite vadovą",
      select_contact_user: "Pasirinkite kontaktinį asmenį",
      contact_user: "Pardavėjo kontaktinis asmuo",
      sign_permission_user: "Pardavėjas",
      pending: "Laukiama",
      signed: "Pasirašyta",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "lt", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
