import { useEffect, useCallback, useState } from "react";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  TextContainer,
  Button,
  ButtonGroup,
  InlineStack,
  EmptyState,
  Link,
  Spinner,
  Select,
} from "@shopify/polaris";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import PrettyDate from "components/format/PrettyDate";
import UploadFile from "features/upload-file/upload-file";
import Files from "features/files/files";
import { fetchFileDetails, setDestroy } from "containers/file/file.Slice";
import { RootState, AppDispatch } from "configureStore";
import { useSelector, useDispatch } from "react-redux";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { API_URL } from "../../constants";
import Loading from "components/loading/Loading";
import CommentsFeature from "components/comment/comments";
import { CommentTypes } from "types/Comment";
import ApprovalPdfForm from "components/customer/build-pdf/ApprovalPdfForm";
import { uploadToDokobit } from "services/upload-file.service";
import UserSelect from "features/user-select/user-select";
import CustomerContactSelect from "features/customer-contact-select/customer-contact-select";
import BuildPdf from "components/customer/build-pdf/BuildPdf";
import UserSelectByPermissions from "features/user-select-by-permission/user-select-by-permission";

function FileContainer() {
  const { t } = useTranslation();
  const { companyId, catId, fileId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [companyUserSigner, setCompanyUserSigner] = useState("");
  const [contactSigner, setContactSigner] = useState("");
  const [signRequestLoading, setSignRequestLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [signType, setSignType] = useState("Dokobit");

  const loadFileData = useCallback(() => {
    if (fileId && companyId) {
      dispatch(fetchFileDetails({ fileId, companyId }));
    }
  }, [fileId, companyId, dispatch]);

  const { fileEntity, isLoading } = useSelector(
    (state: RootState) => state.fileContainerReducer
  );

  useEffect(() => {
    setCompanyUserSigner(fileEntity?.user?.id || "");
  }, [fileId, companyId, JSON.stringify(fileEntity)]);

  useEffect(() => {
    loadFileData();

    return () => {
      dispatch(setDestroy());
    };
  }, [fileId, companyId, loadFileData]);

  const handleOnFileChange = () => {};

  const handleEditClick = (fileId: string) => {
    dispatch(setModalxComponent("FileForm"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
        isCustomerFormRequired: true,
        categoryType: fileEntity?.category?.type,
      })
    );
  };

  // check stritly route param and fetched fileId
  const fetchedFileId = fileEntity?.id ?? null;
  if (fetchedFileId !== fileId) {
    return <Loading />;
  }

  if (!fileEntity) {
    return <Loading />;
  }

  const customFieldsContent = fileEntity.customFields
    ?.filter(({ value }) => value)
    .map(({ label, value, fieldType }) =>
      fieldType === "date" ? (
        <Text as="p" variant="bodyMd" key={label}>
          <strong>{t(label)} : </strong>
          <PrettyDate dateString={value} />
        </Text>
      ) : (
        <Text as="p" variant="bodyMd" key={label}>
          <strong>{t(label)} : </strong> {value}
        </Text>
      )
    );

  const categoriesNode = fileEntity?.categories
    ?.map((categoryEntity) => {
      return categoryEntity.title;
    })
    .join(" ,");

  const customersNode = fileEntity?.customer
    ?.map((customerEntity) => {
      return customerEntity.name;
    })
    .join(" ,");

  const handleUploadClick = async (id: string) => {
    dispatch(setModalxComponent("SignRequestConfirmPopup"));
    dispatch(
      setModalxComponentProps({
        fileId: id,
        contactSigner,
        companyUserSigner,
        customerId: fileEntity.customer.length ? fileEntity.customer[0].id : "",
        nontechuser: selectedUser,
        signType,
      })
    );
  };

  const handleSignType = (value: string) => {
    setSignType(value);
  };

  const signCollection = [
    { label: t("manual_sign"), value: "Manual" },
    { label: "Dokobit", value: "Dokobit" },
  ];

  const handlePdfView = (id: string) => {
    dispatch(setModalxComponent("PdfView"));
    dispatch(
      setModalxComponentProps({
        id,
      })
    );
  };

  const isApprovalRequired =
    fileEntity.filesTemplateCustomField?.templateEntity?.isApprovalRequired;
  const isDokobitEnabled = fileEntity.company?.dokobit?.isActive || false;

  return (
    <Page
      fullWidth
      backAction={{
        content: "File-Page",
        url: `/app/${companyId}/documents/${catId}`,
      }}
      title={fileEntity.name}
      titleMetadata={isLoading && <Spinner size="small" />}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <CommentsFeature id={fileId} CommentTypes={CommentTypes.Files} />
            </Card>
            <Card roundedAbove="sm">
              <div className="flex">
                <Text as="h2" variant="headingSm">
                  {t("files")}
                </Text>
                <div className="flex-grow"></div>
                <ButtonGroup>
                  <UploadFile
                    onChange={handleOnFileChange}
                    subject="customer"
                    subjectId={fileEntity.subjectId}
                    parentFileId={fileId}
                    categoryId={null}
                    categoryType={fileEntity.category?.type}
                  />
                  <BuildPdf
                    customerId={fileEntity?.customer[0]?.id}
                    isDisabled={false}
                    templateId={fileEntity.templateId?.id}
                    fileId={fileEntity.id}
                  />
                </ButtonGroup>
              </div>
              {fileEntity.relatedFiles.length > 0 ? (
                <Files files={fileEntity.relatedFiles} />
              ) : (
                <EmptyState
                  heading={t("no_files")}
                  image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                >
                  <UploadFile
                    onChange={handleOnFileChange}
                    subject="customer"
                    subjectId={fileEntity.subjectId}
                    parentFileId={fileId}
                    categoryId={null}
                    categoryType={fileEntity.category?.type}
                  />
                </EmptyState>
              )}
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <InlineStack align="space-between">
                <Link
                  url={`${API_URL}/${companyId}/files/download/${fileEntity.id}`}
                  target="_blank"
                  removeUnderline
                >
                  {t("download")}
                </Link>
                <Button
                  variant="plain"
                  onClick={() => handleEditClick(fileEntity.id)}
                  accessibilityLabel="Preview"
                >
                  {t("edit")}
                </Button>
              </InlineStack>
              <TextContainer>
                <Text as="p" variant="bodyMd">
                  <strong>{t("document_name")} : </strong>{" "}
                  {fileEntity?.name || fileEntity.title}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("customer")} : </strong> {customersNode}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("category")} : </strong> {categoriesNode}
                </Text>
                {fileEntity.assignedUser?.name && (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("responsible_user")} : </strong>{" "}
                    {fileEntity.assignedUser?.name}
                  </Text>
                )}
                {fileEntity.user?.name && (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("the_file_was_uploaded_by")} : </strong>{" "}
                    {fileEntity.user?.name}
                  </Text>
                )}
                {!fileEntity.externalNumber ? (
                  <></>
                ) : (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("externalNumber")} : </strong>{" "}
                    {fileEntity.externalNumber}
                  </Text>
                )}
                {fileEntity.series && (
                  <Text as="p" variant="bodyMd">
                    <strong>{t("series")} : </strong> {fileEntity.series.title}
                    {fileEntity.number}
                  </Text>
                )}
                {customFieldsContent}
                <Text as="p" variant="bodyMd">
                  <strong>{t("document_date")} : </strong>{" "}
                  {fileEntity.date ? (
                    <PrettyDate dateString={fileEntity.date} />
                  ) : (
                    "N/A"
                  )}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("dateTo")} : </strong>{" "}
                  {fileEntity.dateTo ? (
                    <PrettyDate dateString={fileEntity.dateTo} />
                  ) : (
                    "N/A"
                  )}
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("createdAt")} : </strong>{" "}
                  <PrettyDate
                    dateString={fileEntity.createdAt}
                    showTime={true}
                  />
                </Text>
                <Text as="p" variant="bodyMd">
                  <strong>{t("updatedAt")} : </strong>{" "}
                  <PrettyDate
                    dateString={fileEntity.updatedAt}
                    showTime={true}
                  />
                </Text>
              </TextContainer>
            </Card>

            {isApprovalRequired && (
              <Card roundedAbove="sm">
                <ApprovalPdfForm
                  customerId={
                    fileEntity.subjectId || fileEntity?.customer[0]?.id
                  }
                  preFilledValues={fileEntity.filesTemplateCustomField}
                  fileId={fileEntity.id}
                  fileEntity={fileEntity}
                  isViewOnly={fileEntity.isApproved}
                />
              </Card>
            )}
            {isDokobitEnabled && fileEntity.fileId && (
              <Card roundedAbove="sm">
                <InlineStack align="center">
                  {t("document_signature")}
                </InlineStack>
                <InlineStack align="end">
                  <Button
                    variant="plain"
                    onClick={() => handlePdfView(fileEntity.id)}
                    accessibilityLabel="Preview"
                  >
                    {t("view")}
                  </Button>
                </InlineStack>
                <TextContainer>
                  {/* {fileEntity?.dokobit?.uploadStatus &&
                  <Text as="p" variant="bodyMd">
                    <strong>{t("document_status")} : </strong>{" "}
                    {fileEntity?.dokobit?.uploadStatus}
                  </Text>
                } */}
                  {fileEntity?.dokobit?.signedStatus && (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("sign_status")} : </strong>
                      {fileEntity?.dokobit?.signedStatus}
                    </Text>
                  )}
                  {fileEntity?.dokobit?.contactSigner && (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("contact_signer")} : </strong>
                      {fileEntity?.dokobit?.contactSigner?.name}
                    </Text>
                  )}
                  {fileEntity?.dokobit?.userSigner && (
                    <Text as="p" variant="bodyMd">
                      <strong>{t("selected_user")} : </strong>
                      {fileEntity?.dokobit?.userSigner?.name}
                    </Text>
                  )}
                </TextContainer>
                {!fileEntity?.dokobit?.signersPayload && (
                  <>
                    <div style={{ marginBlock: "8px" }}>
                      <CustomerContactSelect
                        onChange={setContactSigner}
                        value={contactSigner}
                        label={"select_contact"}
                        customerId={
                          fileEntity.customer.length
                            ? fileEntity.customer[0].id
                            : ""
                        }
                        isRequired={true}
                      />
                      <br />
                      <UserSelectByPermissions
                        value={selectedUser}
                        onChange={setSelectedUser}
                        isRequired={true}
                        Permission={"Signature"}
                      />
                      <br />
                      <UserSelect
                        onChange={setCompanyUserSigner}
                        value={companyUserSigner}
                        label={"select_user"}
                        isRequired={true}
                      />
                      <Select
                        label={t("sign_type")}
                        options={signCollection}
                        value={signType}
                        onChange={handleSignType}
                        requiredIndicator={true}
                      />
                    </div>
                    <br />
                    <InlineStack align="center">
                      <Button
                        onClick={() => handleUploadClick(fileEntity.id)}
                        accessibilityLabel="Preview"
                        disabled={
                          !contactSigner || !companyUserSigner || !selectedUser
                        }
                        loading={signRequestLoading}
                      >
                        {t("document_send_signature_request")}
                      </Button>
                    </InlineStack>
                  </>
                )}
              </Card>
            )}
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default FileContainer;
