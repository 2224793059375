import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { setIsModalxOpen } from "components/modalx/modalx.slice";
import { post } from "services/user-select.service";
import { fetchUsers } from "../users/users.slice";
import { fetchUserDetails } from "containers/user/user-container.slice";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
};
const userFormSlice = createSlice({
  name: "userForm",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isSuccessfullySaved = true;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = userFormSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (companyId, data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await post(data, companyId);
    dispatch(destroy());
    dispatch(setIsModalxOpen(false));
    dispatch(fetchUsers(companyId));
    if (data?.userId && companyId) {
      dispatch(fetchUserDetails({ userId: data?.userId, companyId }));
    }
    return true;
  } catch (e) {
    dispatch(setError("400"));
    return false;
  }
};
