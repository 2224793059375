import { LegacyCard, InlineGrid, EmptyState } from "@shopify/polaris";
import React, { useEffect, useCallback } from "react";
import { API_URL } from "constants.js";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchCategoryFilesDetails,
  setDestroy,
} from "containers/document/category-files/catFiles.Slice";
import { RootState, AppDispatch } from "configureStore";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import HeaderLine from "components/routePath/HeaderLine";
import UploadFile from "features/upload-file/upload-file";
import {
  setModalxComponent,
  setModalxComponentProps,
  setModalxStyle,
} from "components/modalx/modalx.slice";
import DataTable from "components/datatable/Datatable";
import Loading from "components/loading/Loading";
import { EditIcon, ViewIcon, CircleDownIcon } from "@shopify/polaris-icons";
import { Customer } from "types/Customer";

interface DocumentDataTableProps {
  id: string;
}

const CategoryFileContainer: React.FC<DocumentDataTableProps> = ({ id }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();

  const loadCategoryFilesData = useCallback(() => {
    if (id && companyId) {
      dispatch(fetchCategoryFilesDetails({ companyId, catId: id }));
    }
  }, [id, companyId, dispatch]);

  const { catFilesData, categoryType, isLoading } = useSelector(
    (state: RootState) => state.documentFilesReducer
  );

  useEffect(() => {
    loadCategoryFilesData();

    return () => {
      dispatch(setDestroy());
    };
  }, [loadCategoryFilesData]);

  const handleEditClick = (fileId: string) => {
    dispatch(setModalxComponent("FileForm"));
    dispatch(
      setModalxComponentProps({
        fileId,
        companyId,
        isCustomerFormRequired: true,
        categoryType,
      })
    );
  };

  const handlePdfView = async (id: string) => {
    dispatch(setModalxComponent("PdfView"));
    dispatch(setModalxStyle("large"));
    dispatch(
      setModalxComponentProps({
        id,
        companyId,
      })
    );
  };

  const handleRowClick = (
    companyId: string | undefined,
    cateId: string,
    fileId: string
  ) => {
    history(`/app/${companyId}/documents/${cateId}/${fileId}`);
  };

  const collection =
    catFilesData?.files?.map((file: any) => ({
      id: file.id,
      name: `${file.name} [${
        file.relatedFiles.length > 0 ? file.relatedFiles.length : "x"
      }]`,
      title: file.title,
      customerName:
        file.customer && Array.isArray(file.customer)
          ? file.customer
              .map((customer: Customer) =>
                customer.name
                  ? `${customer.name}${
                      customer.externalCode ? ` [${customer.externalCode}]` : ""
                    }`
                  : ""
              )
              .filter(Boolean) // Remove any empty strings for customers without a name
              .join(", ")
          : "-",
      assignedUser: file.assignedUser?.name ?? "-",
      seriesTitle: `${file.series?.title || ""}${file.number || ""}`,
      number: file.number,
      date: file.date,
      dateTo: file.dateTo,
      downloadUrl: `${API_URL}/${companyId}/files/download/${file.id}`,
      isVisible: file.fileId ? true : false,
    })) || [];

  const handleOnFileChange = () => {};

  if (isLoading) {
    return <Loading size="small" />;
  }

  const uploadNode = (
    <UploadFile
      onChange={handleOnFileChange}
      subject="customer"
      subjectId={id}
      categoryId={id}
      isCustomerFormRequired={true}
      categoryType={categoryType}
    />
  );

  return id ? (
    <>
      <div style={{ marginBottom: "20px" }}>
        <InlineGrid columns="1fr auto">
          <HeaderLine catId={id} />
          {uploadNode}
        </InlineGrid>
      </div>
      {!catFilesData?.files?.length ? (
        <EmptyState
          heading={t("no_files")}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          {uploadNode}
        </EmptyState>
      ) : (
        <LegacyCard>
          <DataTable
            collection={collection}
            columns={[
              {
                key: "name",
                name: t("title"),
                onClick: (item: any) => handleRowClick(companyId, id, item.id),
                type: "clickableColumn",
                mobileView: "topLeft",
              },
              {
                key: "customerName",
                name: t("customer"),
                onClick: (item: any) => handleRowClick(companyId, id, item.id),
                type: "clickableColumn",
                mobileView: "bottomLeft",
              },
              {
                key: "assignedUser",
                name: t("assignedUser"),
                onClick: (item: any) => handleRowClick(companyId, id, item.id),
                type: "clickableColumn",
                mobileView: "bottomRight",
              },
              {
                key: "seriesTitle",
                name: t("series"),
                onClick: (item: any) => handleRowClick(companyId, id, item.id),
                type: "clickableColumn",
              },
              {
                key: "date",
                name: t("document_date"),
                onClick: (item: any) => handleRowClick(companyId, id, item.id),
                type: "clickabledate",
                isShowTime: false,
                mobileView: "topRight",
              },
              {
                key: "dateTo",
                name: t("dateTo"),
                onClick: (item: any) => handleRowClick(companyId, id, item.id),
                type: "clickabledate",
                isShowTime: false,
              },
              {
                key: "Edit",
                name: t("edit"),
                onClick: (item: any) => handleEditClick(item.id),
                type: "actionBtn",
                Icon: EditIcon,
              },
              {
                key: "view",
                name: t("view"),
                onClick: (item: any) => handlePdfView(item.id),
                type: "specificAction",
                Icon: ViewIcon,
              },
              {
                key: "downloadUrl",
                name: t("download"),
                onClick: (item: any) => window.open(item.downloadUrl, "_blank"),
                url: "downloadUrl",
                type: "downloadurl",
                Icon: CircleDownIcon,
              },
            ]}
          />
        </LegacyCard>
      )}
    </>
  ) : (
    <></>
  );
};

export default CategoryFileContainer;
