import { useState, useEffect, useCallback } from "react";
import { API_URL } from "../../../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FormLayout,
  Banner,
  Form,
  Button,
  TextField,
  Select,
  Checkbox,
  InlineStack,
  Text,
  TextContainer,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import FileTemplateSelect from "components/file-template-select/file-template-select";
import { setIsModalxOpen } from "components/modalx/modalx.slice";
import { CustomField } from "types/CustomField";
import { Product } from "types/Product";
import { useAppDispatch } from "hooks";
import { fetchFileTemplateById } from "containers/files-template-form/files-template-form.service";
import ProductsSelect from "components/products-select/ProductsSelect";
import { fetchFileDetails } from "containers/file/file.Slice";
import { FileEntity } from "types/Files";
import { useSelector } from "react-redux";
import { RootState } from "configureStore";
import DataTable from "components/datatable/Datatable";

interface onSubmitData {
  templateId: string;
  customFields: { [key: string]: string };
  products: Product[];
  isApproved?: boolean;
}

interface PostData extends onSubmitData {
  customerId: string;
}

function ApprovalPdfForm({
  customerId,
  fileId = "",
  preFilledValues = null,
  fileEntity,
  isViewOnly = false,
}: {
  customerId: string;
  preFilledValues: any;
  fileId: string;
  fileEntity: FileEntity;
  isViewOnly: boolean;
}) {
  const { isUserMaster } = useSelector(
    (state: RootState) => state.companySelectReducer
  );
  const isUserManager = fileEntity.isUserManager;
  const [templateId, setTemplateId] = useState("");
  const [customFields, setCustomFields] = useState<CustomField[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForApprove, setIsLoadingForApprove] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const isFormValid = () =>
    templateId &&
    customFields.every(
      (field) => !field.required || (field.value && field.value.length > 0)
    );

  const fetchCustomFields = useCallback(
    async (templateId: string) => {
      try {
        const fileTemplateEntity = await fetchFileTemplateById(
          companyId,
          templateId
        );
        if (preFilledValues) {
          const updatedCustomFields = fileTemplateEntity.customFields.map(
            (field: CustomField) => {
              const matchedValue = preFilledValues[field.label] || "";
              return { ...field, value: matchedValue };
            }
          );

          setCustomFields(updatedCustomFields);
          setProducts(preFilledValues.products || []);
        } else {
          setCustomFields(
            fileTemplateEntity.customFields.map((field: CustomField) => ({
              ...field,
              value: "",
            }))
          );
        }
      } catch (e) {
        console.error(e);
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (templateId) {
      fetchCustomFields(templateId);
    }
  }, [templateId, fetchCustomFields]);

  const handleCustomFieldChange = (index: number, value: string) => {
    const updatedFields = customFields.map((field, i) =>
      i === index ? { ...field, value } : field
    );
    setCustomFields(updatedFields);
  };

  useEffect(() => {
    if (preFilledValues) {
      // Set templateId, products, and isApprovalRequired from preFilledValues
      setTemplateId(preFilledValues.templateId || "");
    }
  }, [JSON.stringify(preFilledValues)]);

  const handleSaveAndApprove = () => {
    handleFormSubmit(true);
  };

  const handleFormSubmit = async (isApproved: boolean) => {
    const formData: PostData = {
      templateId,
      customerId,
      customFields: {},
      products,
      isApproved,
    };
    customFields.forEach((field) => {
      formData.customFields[field.label] = field.value;
    });

    if (companyId) {
      try {
        isApproved ? setIsLoadingForApprove(true) : setIsLoading(true);
        const action = fileId ? update : submit;
        const fileEntity = await action(companyId, formData, fileId);
        if (fileEntity.id) {
          dispatch(fetchCustomer({ companyId, customerId }));
          dispatch(fetchFileDetails({ fileId, companyId }));
          setIsSuccessful(true);
          setTimeout(() => {
            dispatch(setIsModalxOpen(false));
          }, 1500);
          isApproved ? setIsLoadingForApprove(false) : setIsLoading(false);
        } else {
          setIsFailed(true);
          setIsLoading(false);
          setIsLoadingForApprove(false);
        }
      } catch (e) {
        setIsFailed(true);
        setIsLoading(false);
        setIsLoadingForApprove(false);
      }
    }
  };

  const isApproved = fileEntity.isApproved;

  return (
    <div className="user-form">
      <Form onSubmit={() => handleFormSubmit(false)}>
        <div className="form-warnings">
          {isFailed && (
            <div className="">
              <Banner tone="critical">{t("error")}</Banner>
            </div>
          )}
          {isSuccessful && (
            <div className="">
              <Banner tone="info">{t("ok")}</Banner>
            </div>
          )}
        </div>
        <FormLayout>
          {isViewOnly ? (
            <TextContainer>
              <Text as="p" variant="bodyMd">
                <strong>{t("file_template_select")} : </strong>{" "}
                {fileEntity.templateId?.name}
              </Text>
            </TextContainer>
          ) : (
            <FileTemplateSelect
              onChange={setTemplateId}
              value={templateId}
              isRequired={true}
            />
          )}
          {customFields.map((field, index) => {
            if (!field.value) {
              return null;
            }

            const renderField = () => {
              switch (field.type) {
                case "string":
                case "textarea":
                  return (
                    <TextField
                      label={field.name}
                      value={field.value}
                      autoComplete="off"
                      multiline={field.type === "textarea" ? 4 : undefined}
                      onChange={(value: string) =>
                        handleCustomFieldChange(index, value)
                      }
                      requiredIndicator={field.required}
                    />
                  );
                case "date":
                  return (
                    <TextField
                      label={field.name}
                      value={field.value}
                      type="date"
                      autoComplete="off"
                      onChange={(value: string) =>
                        handleCustomFieldChange(index, value)
                      }
                      requiredIndicator={field.required}
                    />
                  );
                case "select":
                  return (
                    <Select
                      label={field.name}
                      options={[
                        {
                          label: t(
                            "files_template_custom_type_select_select_an_option"
                          ),
                          value: "",
                        },
                        ...field.options.map((option) => ({
                          label: option,
                          value: option,
                        })),
                      ]}
                      value={field.value}
                      onChange={(value: string) =>
                        handleCustomFieldChange(index, value)
                      }
                      requiredIndicator={field.required}
                    />
                  );
                case "products":
                  return (
                    <ProductsSelect
                      onChange={(value: any) => {
                        if (
                          JSON.stringify(products) !== JSON.stringify(value)
                        ) {
                          handleCustomFieldChange(index, value);
                          setProducts(value);
                        }
                      }}
                      initialProducts={products}
                    />
                  );
                default:
                  return null;
              }
            };

            const renderViewOnlyField = () => {
              if (field.type === "products") {
                return (
                  <DataTable
                    collection={products}
                    columns={[
                      { key: "#" },
                      { key: "title", name: t("title"), mobileView: "topLeft" },
                      { key: "sku", name: t("sku"), mobileView: "bottomLeft" },
                      {
                        key: "quantity",
                        suffix: "unit.title",
                        type: "number",
                        name: t("quantity"),
                        isDisabledTotal: false,
                        mobileView: "topRight",
                      },
                      {
                        key: "price",
                        type: "currency",
                        name: t("unitPrice"),
                        mobileView: "bottomRight",
                      },
                    ]}
                  />
                );
              }
              return (
                <TextContainer>
                  <Text as="p" variant="bodyMd">
                    <strong>{field.name}:</strong> {field.value}
                  </Text>
                </TextContainer>
              );
            };

            return (
              <div key={field.id}>
                {isViewOnly ? renderViewOnlyField() : renderField()}
              </div>
            );
          })}

          {!isApproved && (
            <InlineStack align="space-between">
              <Button loading={isLoading} disabled={!isFormValid()} submit>
                {t("save")}
              </Button>
              {/* manager and master can approve */}
              {(isUserMaster || isUserManager) && (
                <Button
                  loading={isLoadingForApprove}
                  disabled={!isFormValid()}
                  onClick={handleSaveAndApprove}
                >
                  {t("save_and_approve")}
                </Button>
              )}
            </InlineStack>
          )}
        </FormLayout>
      </Form>
    </div>
  );
}

export default ApprovalPdfForm;

function submit(companyId: string, data: PostData) {
  const url = `${API_URL}/${companyId}/files/build-pdf`;
  return axios.post(url, data).then((response) => response.data);
}

function update(companyId: string, data: PostData, fileid: string) {
  const url = `${API_URL}/${companyId}/files/build-pdf/${fileid}`;
  return axios.patch(url, data).then((response) => response.data);
}
