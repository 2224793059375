import UNDERHILL from "../../assets/images/UNDERHILL.png";
import MNPK from "../../assets/images/MNPK.png";
import eximag from "../../assets/images/eximag-logo_v2.png";
import UHBAGRO from "../../assets/images/UHB_AGRO.png";
import UHBSERVE from "../../assets/images/UHB_SERVE.png";
import UHBLOGO from "../../assets/images/UHB_LOGO.png";
import TRASKIJA from "../../assets/images/TRAŠKIJA.png";
import UHBFOOD from "../../assets/images/UHB_FOOD.png";
import UHBCHEM from "../../assets/images/UHB_CHEM.png";
import UZKIMYOIMPEKS from "../../assets/images/UZKIMYOIMPEKS.png";
import SAMARKANDNPK from "../../assets/images/SAMARKANDNPK.png";
import TRANSCHEMICALCARO from "../../assets/images/trans_chemical_cargo.png";

export const sections = {
  one: {
    id: "7a06b316-6350-4454-adea-8c176a27b872",
    imgSrc: UNDERHILL,
    text: "Underhill Baltic, UAB.",
  },
  twoUnderline: {
    id: "726a4ffe-1e1b-4084-b976-285bedcd59e8",
    imgSrc: UHBLOGO,
    text: "UHB, UAB.",
  },
  three: [
    {
      id: "2bc99788-d127-48e5-9296-8a4b6b05f789",
      imgSrc: UHBAGRO,
      text: "UHB AGRO, UAB.",
    },
    {
      id: "26440f77-3230-46e7-8cd2-45749447ca40",
      imgSrc: eximag,
      text: "EXIMAG, UAB.",
    },
  ],
  four: [
    {
      id: "ffc23cf6-1819-473c-8e8d-192f3e48d1e2",
      imgSrc: MNPK,
      text: "MARIJAMPOLĖS NPK, UAB.",
    },
    {
      id: "8c1d50dc-85b8-48df-be85-fa680d698f9f",
      imgSrc: UHBSERVE,
      text: "UHB SERVICE, UAB.",
    },
  ],
  five: [
    {
      id: "25c810b7-2313-4cd5-9ade-8a97c3ff93eb",
      imgSrc: TRASKIJA,
      text: "TRAŠKIJA, UAB.",
    },
    {
      id: "a7ace0f3-8c39-4080-a5f0-ea9c4485d587",
      imgSrc: UHBFOOD,
      text: "UHB FOOD, UAB.",
    },
  ],
  six: {
    id: "f1961398-f878-4078-b38d-837f7261b418",
    imgSrc: UHBCHEM,
    text: "UHB CHEMICALS, UAB.",
  },
  seven: [
    {
      id: "80ebe3bf-a921-4701-a8e6-da9bd0f6386f",
      imgSrc: TRANSCHEMICALCARO,
      text: "Trans Chemical cargo, UAB",
    },
    {
      id: "b9dea3c3-691c-4104-a9cb-c9f5a6c166b1",
      imgSrc: SAMARKANDNPK,
      text: "Samarkand NPK",
    },
    {
      id: "eef6deb8-a9f6-4423-901b-71d73d7e00e2",
      imgSrc: UZKIMYOIMPEKS,
      text: "TRADING HOUSE UZKIMYOIMPEKS- LITHUANIA, UAB.",
    },
  ],
};

export const coordinateJson = [
  {
    id: "7a06b316-6350-4454-adea-8c176a27b872",
    imgSrc: UNDERHILL,
    title: "Underhill Baltic, UAB.",
    shape: "rect",
    name: "Underhill Baltic, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [541, 125, 898, 160],
    rect: [
      [541, 125],
      [898, 160],
    ],
  },
  {
    id: "726a4ffe-1e1b-4084-b976-285bedcd59e8",
    imgSrc: UHBLOGO,
    title: "UHB, UAB.",
    shape: "rect",
    name: "UHB, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [651, 226, 783, 280],
    rect: [
      [651, 226],
      [783, 280],
    ],
  },
  {
    id: "2bc99788-d127-48e5-9296-8a4b6b05f789",
    imgSrc: UHBAGRO,
    title: "UHB AGRO, UAB",
    shape: "rect",
    name: "UHB AGRO, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [44, 407, 233, 454],
    rect: [
      [44, 407],
      [233, 454],
    ],
  },
  {
    id: "26440f77-3230-46e7-8cd2-45749447ca40",
    imgSrc: eximag,
    title: "EXIMAG, UAB.",
    shape: "rect",
    name: "EXIMAG, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [637, 538, 789, 571],
    rect: [
      [637, 538],
      [789, 571],
    ],
  },
  {
    id: "ffc23cf6-1819-473c-8e8d-192f3e48d1e2",
    imgSrc: MNPK,
    title: "MARIJAMPOLĖS NPK, UAB.",
    shape: "rect",
    name: "MARIJAMPOLĖS NPK, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [243, 434, 442, 510],
    rect: [
      [243, 434],
      [442, 510],
    ],
  },
  {
    id: "8c1d50dc-85b8-48df-be85-fa680d698f9f",
    imgSrc: UHBSERVE,
    title: "UHB SERVICE, UAB.",
    shape: "rect",
    name: "UHB SERVICE, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [1200, 406, 1414, 453],
    rect: [
      [1200, 406],
      [1414, 453],
    ],
  },
  {
    id: "25c810b7-2313-4cd5-9ade-8a97c3ff93eb",
    imgSrc: TRASKIJA,
    title: "TRAŠKIJA, UAB.",
    shape: "rect",
    name: "TRAŠKIJA, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [99999, 99999, 99999, 99999],
    rect: [
      [99999, 99999],
      [99999, 99999],
    ],
  },
  {
    id: "a7ace0f3-8c39-4080-a5f0-ea9c4485d587",
    imgSrc: UHBFOOD,
    title: "UHB FOOD, UAB.",
    shape: "rect",
    name: "UHB FOOD, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [999, 453, 1183, 500],
    rect: [
      [999, 453],
      [1183, 500],
    ],
  },
  {
    id: "f1961398-f878-4078-b38d-837f7261b418",
    imgSrc: UHBCHEM,
    title: "UHB CHEMICALS, UAB.",
    shape: "rect",
    name: "UHB CHEMICALS, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [99999, 99999, 99999, 99999],
    rect: [
      [99999, 99999],
      [99999, 99999],
    ],
  },
  {
    id: "80ebe3bf-a921-4701-a8e6-da9bd0f6386f",
    imgSrc: TRANSCHEMICALCARO,
    title: "Trans Chemical cargo, UAB",
    shape: "rect",
    name: "Trans Chemical cargo, UAB",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [1157, 675, 1419, 734],
    rect: [
      [1157, 675],
      [1419, 734],
    ],
  },
  {
    id: "b9dea3c3-691c-4104-a9cb-c9f5a6c166b1",
    imgSrc: SAMARKANDNPK,
    title: "Samarkand NPK",
    shape: "rect",
    name: "Samarkand NPK",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [495, 671, 692, 734],
    rect: [
      [495, 671],
      [692, 734],
    ],
  },
  {
    id: "eef6deb8-a9f6-4423-901b-71d73d7e00e2",
    imgSrc: UZKIMYOIMPEKS,
    title: "TRADING HOUSE UZKIMYOIMPEKS- LITHUANIA, UAB.",
    shape: "rect",
    name: "TRADING HOUSE UZKIMYOIMPEKS- LITHUANIA, UAB.",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [720, 680, 1011, 727],
    rect: [
      [720, 680],
      [1011, 727],
    ],
  },
  {
    id: "#",
    title: "SKANEJA",
    shape: "rect",
    name: "SKANEJA",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [490, 457, 660, 495],
    rect: [
      [490, 457],
      [660, 495],
    ],
  },
  {
    id: "##",
    text: "STOLMINA",
    shape: "rect",
    name: "STOLMINA",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [755, 464, 950, 494],
    rect: [
      [755, 464],
      [950, 494],
    ],
  },
  {
    id: "###",
    title: "UHB GROUP.",
    shape: "rect",
    name: "UHB GROUP",
    fillColor: "rgba(255, 255, 255, 0)",
    strokeColor: "rgba(255, 255, 255, 0)",
    responsive: false,
    coords: [20, 37, 183, 74],
    rect: [
      [20, 37],
      [183, 74],
    ],
  },
];
