/* eslint-disable no-useless-computed-key */
import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { DropZone, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setModalxComponent,
  setModalxComponentProps,
  setDestroy,
} from "components/modalx/modalx.slice";
import { upload, replaceFile } from "services/upload-file.service";
import { fetchCustomer } from "containers/customer/customer-container.slice";

function UploadFile({
  onChange,
  subject,
  subjectId,
  categoryId,
  isCustomerFormRequired,
  parentFileId,
  categoryType,
}) {
  const dispatch = useDispatch();
  const { companyId, projectId, customerId } = useParams();

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const { t } = useTranslation();

  const handleDropZoneDrop = useCallback(
    // eslint-disable-next-line no-unused-vars
    async (acceptedFiles) => {
      const uploadedFiles = [];
      try {
        for (let file of acceptedFiles) {
          dispatch(setModalxComponent("Loading"));
          const { data } = await upload(file, companyId);
          uploadedFiles.push(data);
        }
        if (uploadedFiles.length === 1) {
          if (subject != "replace") {
            dispatch(setModalxComponent("FileForm"));
            dispatch(
              setModalxComponentProps({
                fileId: uploadedFiles[0],
                companyId,
                projectId,
                categoryId,
                isCustomerFormRequired,
                parentFileId,
                categoryType,
                customerId,
                subject,
                subjectId,
                isCategoryRequired: !parentFileId,
              })
            );
          } else {
            // uploadedFiles[0]
            const payload = {
              prevId: parentFileId,
              nextId: uploadedFiles[0],
              customerId,
            };
            await replaceFile(companyId, payload);
            dispatch(fetchCustomer({ companyId, customerId }));
            dispatch(setDestroy());
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    [
      companyId,
      dispatch,
      subject,
      subjectId,
      categoryId,
      isCustomerFormRequired,
      parentFileId,
      categoryType,
      projectId,
      customerId,
    ]
  );
  const toggleOpenFileDialog = useCallback(
    // eslint-disable-next-line no-shadow
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  );

  return (
    <>
      <Button onClick={toggleOpenFileDialog}>{t("upload")}</Button>
      <div style={{ display: "none" }}>
        <DropZone
          allowMultiple={false}
          openFileDialog={openFileDialog}
          onDropAccepted={handleDropZoneDrop}
          onFileDialogClose={toggleOpenFileDialog}
        >
          <DropZone.FileUpload />
        </DropZone>
      </div>
    </>
  );
}

export default UploadFile;

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  isFileInputs: PropTypes.bool,
  subject: PropTypes.string,
  isCustomerFormRequired: PropTypes.bool,
  parentFileId: PropTypes.string,
};

UploadFile.defaultProps = {
  isCustomerFormRequired: false,
  parentFileId: null,
};
