import React, { useState, useEffect } from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import pdfjsLib from "pdfjs-dist/package.json";
import Loading from "components/loading/Loading";
import { useParams } from "react-router-dom";
import { fetchFileUrl } from "services/upload-file.service";

const PdfModalViewer = ({ id }: { id: string }) => {
  const { companyId } = useParams<Record<string, string>>();
  const [pdfBlob, setPdfBlob] = useState<string | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        const pdfUrl = await fetchFileUrl(id, companyId);
        setPdfBlob(pdfUrl);
      } catch (error) {
        console.error("Error fetching PDF:", error);
      } finally {
        setLoading(false);
      }
    }
    if (id && companyId) {
      fetchData();
    }
  }, [id, companyId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Worker
      workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.js`}
    >
      {pdfBlob && <Viewer fileUrl={pdfBlob} />}
    </Worker>
  );
};

export default PdfModalViewer;
