import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button } from "@shopify/polaris";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";

type BuildPdfParams = {
  customerId: string;
  isDisabled: boolean;
  isCustomerApproved?: boolean;
  templateId?: string;
  fileId?: string;
  signersInfo?: {
    contactSigner?: string;
    companyUserSigner?: string;
    nontechuser?: string;
    signType?: string;
  };
};

function BuildPdf({
  customerId,
  isDisabled = false,
  isCustomerApproved = false,
  templateId = "",
  fileId = "",
  signersInfo,
}: BuildPdfParams) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(setModalxComponent("BuildPdfForm"));
    dispatch(
      setModalxComponentProps({
        customerId,
        isCustomerApproved,
        template: templateId,
        fileId,
        signersInfo,
      })
    );
  };

  return (
    <Button onClick={handleOnClick} disabled={isDisabled}>
      {t("files_build_pdf")}
    </Button>
  );
}

export default BuildPdf;
