import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import { FormLayout, TextField, Form, Button, Banner } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchUsersPermissionGroupsList } from "containers/users-permissions-groups/users-permissions-groups-container.slice";
import {
  createUsersPermissionsGroups,
  updateUsersPermissionsGroups,
  fetchUsersPermissionsGroupsById,
} from "services/users-permissions-groups.service";
import PermissionsSelect from "components/permissions-select.tsx/permissions-select";

interface UsersPermissionsGroupsFormProps {
  // onSubmit?: (result: any) => void;
  id?: string;
}

const UsersPermissionsGroupsForm: React.FC<UsersPermissionsGroupsFormProps> = ({
  id,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [name, setName] = useState<string>("");
  const [permissions, setPermissions] = useState<string[]>([]);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      name,
      permissions,
    };
    const action = id
      ? updateUsersPermissionsGroups
      : createUsersPermissionsGroups;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      if (companyId) dispatch(fetchUsersPermissionGroupsList(companyId));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const fetchData = useCallback(
    async (id: string) => {
      const { name, permissions } = await fetchUsersPermissionsGroupsById(
        companyId!,
        id
      );
      setName(name);
      setPermissions(permissions);
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div>
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div>
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("name")}
          id="label"
          name="label"
          value={name}
          onChange={(value) => setName(value)}
          autoComplete="off"
        />
        <PermissionsSelect value={permissions} onChange={setPermissions} />
        <Button onClick={handleFormSubmit} disabled={!name} loading={isLoading}>
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default UsersPermissionsGroupsForm;
