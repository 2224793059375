import React, { useState, useEffect, useCallback } from "react";
import { InlineStack, TextField, Button } from "@shopify/polaris";
import { createComment, updateComment } from "./comments.service";
import { fetchProjectById } from "services/project.service";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Comment } from "types/Comment";
import "./style.scss";
import CommentList from "containers/comments-list/Comments-list";
import { fetchById } from "services/upload-file.service";

interface CommentProps {
  id: string;
  CommentTypes: string;
}

const Comments: React.FC<CommentProps> = ({ id, CommentTypes = "" }) => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [comment, setComment] = useState<string>("");
  const [newComment, setNewComment] = useState<string>("");
  const [commentList, setCommentList] = useState<Comment[]>([]);
  const [targetComment, setTargetComment] = useState<Comment | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<string | undefined>();

  const handleFormSubmit = async (commentId?: string) => {
    const formData = {
      comment: commentId ? newComment : comment,
      projectId: CommentTypes === "projects" ? id : null,
      fileId: CommentTypes === "files" ? id : null,
    };
    const action = commentId ? updateComment : createComment;
    try {
      setIsLoading(commentId ? "update" : "save");
      await action(formData, companyId!, commentId);
      fetchData(id);
      setComment("");
      setTargetComment(null);
      setSuccessMessage("ok");
      setIsLoading(undefined);
    } catch (e) {
      setErrMsg("error");
      setIsLoading(undefined);
    }
  };

  const fetchData = useCallback(
    async (id: string) => {
      try {
        setIsLoading("fetch");
        switch (CommentTypes) {
          case "projects": {
            const data = await fetchProjectById(companyId!, id);
            setCommentList(data.comments);
            break;
          }
          case "files": {
            const data = await fetchById(id, companyId!);
            setCommentList(data.comments);
            break;
          }
          default: {
            break;
          }
        }
        setIsLoading(undefined);
      } catch (err) {
        setIsLoading(undefined);
      }
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  const editHandler = (comment: Comment | null) => {
    setTargetComment(comment);
    setNewComment(comment?.comment || "");
  };

  return (
    <>
      <div className="textarea-marginBottom_20">
        <TextField
          label={t("comment")}
          id="description"
          name="description"
          multiline={4}
          value={comment}
          onChange={setComment}
          autoComplete="off"
        />
        <div className="cmt-marginTop_10">
          <InlineStack wrap={false} align={"end"}>
            <Button
              onClick={() => handleFormSubmit()}
              disabled={!comment}
              loading={isLoading === "save"}
            >
              {t("make_a_comment")}
            </Button>
          </InlineStack>
        </div>
      </div>

      <CommentList
        comments={commentList}
        targetComment={targetComment}
        newComment={newComment}
        isLoading={isLoading}
        onEdit={editHandler}
        onSave={handleFormSubmit}
        onCancel={() => editHandler(null)}
        onNewCommentChange={setNewComment}
      />
    </>
  );
};

export default Comments;
