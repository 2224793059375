import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select, Combobox, LegacyStack, Tag, Listbox } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";

function FileCategorySelect({
  value,
  onChange,
  isRequired,
  subject = "customer",
  allowMultiple = false,
}) {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");
  const [selectedTags, setSelectedTags] = useState(value);
  const [inputValue, setInputValue] = useState("");
  const [suggestion, setSuggestion] = useState("");

  useEffect(() => {
    if (allowMultiple && isRequired && !selectedTags.length) {
      setError(t("field_is_required"));
    } else if (!allowMultiple && isRequired && (!value.length || !value[0])) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }
  }, [JSON.stringify(value)]);

  const handleSingleSelectChange = (selectedValue) => {
    onChange([selectedValue]);
  };

  const handleMultiSelectChange = useCallback(
    (selected) => {
      if (selected) {
        const updatedSelection = selectedTags.includes(selected)
          ? selectedTags.filter((tag) => tag !== selected)
          : [...selectedTags, selected];
        setSelectedTags(updatedSelection);
        setInputValue("");
        setSuggestion("");
        onChange(updatedSelection);
      }
    },
    [selectedTags]
  );

  const handleRemoveTag = useCallback(
    (tag) => () => {
      const updatedSelection = selectedTags.filter((item) => item !== tag);
      setSelectedTags(updatedSelection);
      onChange(updatedSelection);
    },
    [selectedTags]
  );

  function sortCategories(data) {
    // Helper function to find children recursively with customized prefix
    const findChildren = (parentId, depth) => {
      return data
        .filter((item) => item.parentCategory === parentId)
        .map((item) => {
          return {
            ...item,
            title: `${"﹘".repeat(depth)}⋗ ${item.title}`,
            children: findChildren(item.id, depth + 1),
          };
        });
    };

    // Get all parent categories (where parentCategory is null)
    const parents = data.filter((item) => item.parentCategory === null);

    // Build the sorted structure
    let sortedData = [];

    parents.forEach((parent) => {
      sortedData.push(parent);
      sortedData = sortedData.concat(findChildren(parent.id, 1));
    });

    // Flatten the result
    const flattenData = (items) => {
      return items.reduce((acc, item) => {
        acc.push(item);
        if (item.children) {
          acc = acc.concat(flattenData(item.children));
        }
        return acc;
      }, []);
    };

    return flattenData(sortedData);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const collection = await fetch(companyId, subject);
        // Sort and print the result
        const sortedCategories = sortCategories(collection);
        const dataCollection = _.map(sortedCategories, (row) => ({
          label: row.title,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId, subject]);

  const optionsMarkup = collection.map((option, index) => {
    const isSelected = selectedTags.includes(option.value);
    return (
      <Listbox.Option
        key={index}
        value={option.value}
        selected={isSelected}
        accessibilityLabel={option.label}
      >
        <Listbox.TextOption selected={isSelected}>
          {option.label}
        </Listbox.TextOption>
      </Listbox.Option>
    );
  });

  return (
    <div className="file-category-select">
      {allowMultiple ? (
        <Combobox
          allowMultiple
          activator={
            <Combobox.TextField
              label={t("file_category_user")}
              value={inputValue}
              placeholder={t("search_tags")}
              suggestion={suggestion}
              onChange={setInputValue}
              requiredIndicator={isRequired}
              error={error}
              verticalContent={
                <LegacyStack spacing="extraTight">
                  {selectedTags.map((tag, index) => (
                    <Tag key={index} onRemove={handleRemoveTag(tag)}>
                      {collection.find((item) => item.value === tag)?.label}
                    </Tag>
                  ))}
                </LegacyStack>
              }
            />
          }
        >
          <Listbox onSelect={handleMultiSelectChange}>{optionsMarkup}</Listbox>
        </Combobox>
      ) : (
        <Select
          label={t("file_category_user")}
          options={collection}
          onChange={handleSingleSelectChange}
          value={value.length ? value[0] : ""}
          disabled={isLoading}
          requiredIndicator={isRequired}
          error={error}
        />
      )}
    </div>
  );
}

export default FileCategorySelect;

FileCategorySelect.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  subject: PropTypes.string,
  allowMultiple: PropTypes.bool,
};

FileCategorySelect.defaultProps = {
  isRequired: false,
  allowMultiple: false,
};

function fetch(companyId, subject) {
  const url = `${API_URL}/${companyId}/files/categories-fetch/${subject}`;
  return axios.get(url).then((response) => response.data);
}
