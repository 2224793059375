import { useEffect, useState, useCallback } from "react";
import axios from "axios";
import _ from "lodash";
import { Combobox, LegacyStack, Tag, Listbox } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";

interface FileTemplateSelectProps {
  value: string[];
  onChange: (updatedSelection: string[]) => void;
  isRequired?: boolean;
  disabled?: boolean;
  viewAll?: boolean;
}

interface Option {
  label: string;
  value: string;
}

const FileTemplateSelect: React.FC<FileTemplateSelectProps> = ({
  value,
  onChange,
  isRequired = false,
  disabled = false,
  viewAll = false,
}) => {
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();

  const [collection, setCollection] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedTags, setSelectedTags] = useState<string[]>(value || []);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (!_.isEqual(selectedTags, value)) {
      setSelectedTags(value || []);
    }
  }, [value, selectedTags]);

  const handleMultiSelectChange = useCallback(
    (selected: string) => {
      if (selected) {
        const updatedSelection = selectedTags.includes(selected)
          ? selectedTags.filter((tag) => tag !== selected)
          : [...selectedTags, selected];
        setSelectedTags(updatedSelection);
        onChange(updatedSelection);
      }
    },
    [selectedTags, onChange]
  );

  const handleRemoveTag = useCallback(
    (tag: string) => () => {
      const updatedSelection = selectedTags.filter((item) => item !== tag);
      setSelectedTags(updatedSelection);
      onChange(updatedSelection);
    },
    [selectedTags, onChange]
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const data = await fetchTemplates(companyId || "");
        const dataCollection: Option[] = _.map(
          _.filter(data, (row) => true), // Adjust the filter logic if needed
          (row) => ({
            label: row.name,
            value: row.id,
          })
        );
        setCollection(dataCollection);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    if (companyId) {
      fetchData();
    }
  }, [companyId, viewAll]);

  useEffect(() => {
    if (isRequired && selectedTags.length === 0) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }
  }, [isRequired, selectedTags, t]);

  const optionsMarkup = collection.map((option) => {
    const isSelected = selectedTags.includes(option.value);
    return (
      <Listbox.Option
        key={option.value}
        value={option.value}
        selected={isSelected}
        accessibilityLabel={option.label}
      >
        <Listbox.TextOption selected={isSelected}>
          {option.label}
        </Listbox.TextOption>
      </Listbox.Option>
    );
  });

  return (
    <div className="file-template-select">
      <Combobox
        allowMultiple
        activator={
          <Combobox.TextField
            autoComplete="off"
            label={t("file_template_select")}
            value={inputValue}
            placeholder={t("search_templates")}
            onChange={setInputValue}
            error={error}
            disabled={isLoading || disabled}
            verticalContent={
              <LegacyStack spacing="extraTight">
                {selectedTags.map((tag) => (
                  <Tag key={tag} onRemove={handleRemoveTag(tag)}>
                    {collection.find((item) => item.value === tag)?.label}
                  </Tag>
                ))}
              </LegacyStack>
            }
          />
        }
      >
        <Listbox onSelect={handleMultiSelectChange}>{optionsMarkup}</Listbox>
      </Combobox>
    </div>
  );
};

export default FileTemplateSelect;

const fetchTemplates = (companyId: string): Promise<any[]> => {
  const url = `${API_URL}/${companyId}/files/templates/fetch-file-templates`;
  return axios.get(url).then((response) => response.data);
};
