import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";

function FileTemplateSelect({
  value,
  onChange,
  isRequired,
  disabled = false,
  isCustomerApproved = false,
  viewAll = false,
  templateId = "",
}) {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");

  const handleChange = (selectedValue) => {
    if (isRequired && !selectedValue) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }

    onChange(selectedValue);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const collection = await fetch(companyId);
        let dataCollection = [];
        if (templateId) {
          dataCollection = _.flatMap(
            _.filter(collection, (row) => {
              return row.id === templateId; // Filter by templateId
            }),
            (row) =>
              _.map(row.relatedTo, (related) => ({
                label: related.name,
                value: related.id,
              }))
          );
        } else {
          dataCollection = _.map(
            _.filter(collection, (row) => {
              return (
                !(
                  row.isValidFinancialApprovalRequired === true &&
                  isCustomerApproved === false
                ) || viewAll
              );
            }),
            (row) => ({
              label: row.name,
              value: row.id,
            })
          );
        }
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId]);

  return (
    <div className="file-template-select">
      <Select
        label={t("file_template_select")}
        options={collection}
        onChange={handleChange}
        value={value}
        disabled={isLoading || disabled}
      />
    </div>
  );
}

export default FileTemplateSelect;

FileTemplateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  subject: PropTypes.string,
  isRequired: PropTypes.bool,
};

FileTemplateSelect.defaultProps = {
  isRequired: false,
};

function fetch(companyId) {
  const url = `${API_URL}/${companyId}/files/templates/fetch-file-templates`;
  return axios.get(url).then((response) => response.data);
}
