/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { Card, Text, BlockStack, InlineGrid, Badge } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";
import "./style.scss";
import PrettyDate from "components/format/PrettyDate";
import Currency from "components/format/currency/currency";

function ProjectContainer({ project }) {
  const history = useNavigate();
  const { t } = useTranslation();
  const { projectBoardId, companyId } = useParams();

  const totalPrice = (() => {
    let projectTotalPrice = 0;
    project.products.forEach((product) => {
      projectTotalPrice += product.totalPrice;
    });

    return projectTotalPrice;
  })();

  const handleShowProject = () => {
    history(
      `/app/${companyId}/project-boards/${projectBoardId}/project/${project.id}`
    );
  };

  return (
    <div
      className="project-container"
      onClick={handleShowProject}
      style={{ cursor: "pointer" }}
    >
      <Card>
        <BlockStack gap="200">
          <InlineGrid columns="1fr auto">
            <Text as="h2" variant="headingSm">
              {`${project.customer.name} [${project.customer.externalCode}]`}
            </Text>
            {project.isImportant && (
              <Badge tone="critical">{t("important")}</Badge>
            )}
          </InlineGrid>
          <Text variant="bodyMd" as="p">
            {project.name}
          </Text>
          <InlineGrid columns="1fr auto">
            <Text as="p" fontWeight="semibold">
              {project.user.name}
            </Text>
            <Text as="p" fontWeight="regular">
              <PrettyDate dateString={project.updatedAt} />
            </Text>
          </InlineGrid>
          <Currency value={totalPrice} isSymbol={true} />
        </BlockStack>
      </Card>
    </div>
  );
}

export default ProjectContainer;
