import { useState, useEffect, useCallback } from "react";
import { API_URL } from "../../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FormLayout,
  Form,
  Button,
  Text,
  TextField,
  InlineStack,
  BlockStack,
  Tooltip,
  Card,
  Badge,
  EmptyState,
  ButtonGroup,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "hooks";
import { Customer } from "types/Customer";
import { useSelector } from "react-redux";
import { RootState } from "configureStore";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "components/modalx/modalx.slice";
import { FileEntity } from "types/Files";
import UploadFile from "features/upload-file/upload-file";
import ReplaceFile from "features/replace-file/replace-file";
import BuildPdf from "components/customer/build-pdf/BuildPdf";
import Files from "features/files/files";

interface PostData {
  //advance_invoice_deferral_days: number;
  invoice_deferral_days: number;
  //advance_payment_percent: number;
  debt_limit: number;
  // penalty_percent: number;
  // storage_costs_per_unit: number;
  // non_performance_percent: number;
}

function CustomerFinanceForm({
  customer,
  onSubmit,
  fileEntity,
}: {
  customer: Customer;
  onSubmit: () => void;
  fileEntity: FileEntity;
}) {
  const { t } = useTranslation();
  const [formValues, setFormValues] = useState<PostData>({
    //advance_invoice_deferral_days: 0,
    invoice_deferral_days: 0,
    //advance_payment_percent: 0,
    debt_limit: 0,
    // penalty_percent: 0,
    // storage_costs_per_unit: 0,
    // non_performance_percent: 0
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingForApprove, setIsLoadingForApprove] = useState(false);
  const { companyId } = useParams();
  const dispatch = useAppDispatch();
  const { isUserMaster } = useSelector(
    (state: RootState) => state.companySelectReducer
  );
  const { role } = useSelector((state: RootState) => state.authReducer);
  const isUserManager = customer.isUserManager;
  const isApproved = customer.isApproved;
  const isFinanceFormSent = customer.isFinanceFormSent;
  const isFinanceActive = customer.isFinanceActive;

  useEffect(() => {
    if (customer.finance) {
      const { id, createdAt, updatedAt, dateTo, ...filteredFinance } =
        customer.finance;
      setFormValues((prev) => ({
        ...prev,
        ...filteredFinance,
      }));
    }
  }, [customer.finance]);

  const validateField = (field: string, value: any): string => {
    switch (field) {
      case "advance_invoice_deferral_days":
      case "invoice_deferral_days":
        return value < 0 ? t("Must be a positive integer") : "";
      case "advance_payment_percent":
      case "penalty_percent":
      case "non_performance_percent":
        return value < 0 || value > 100
          ? t("Must be a percentage between 0 and 100")
          : "";
      case "debt_limit":
      case "storage_costs_per_unit":
        return value < 0 ? t("Must be a positive number") : "";
      case "dateTo":
        return !value ? t("Date is required") : "";
      default:
        return "";
    }
  };

  const handleInputChange = (field: string, value: string | number) => {
    setErrors((prev) => ({ ...prev, [field]: validateField(field, value) }));
    setFormValues((prev) => ({ ...prev, [field]: value }));
  };

  const isFormValid = useCallback(() => {
    const currentErrors: Record<string, string> = {};
    Object.entries(formValues).forEach(([field, value]) => {
      const error = validateField(field, value);
      if (error) currentErrors[field] = error;
    });

    if (JSON.stringify(currentErrors) !== JSON.stringify(errors)) {
      setErrors(currentErrors);
    }

    return Object.keys(currentErrors).length === 0;
  }, [formValues, errors]);

  const handleFormSubmit = async (isApprove?: boolean) => {
    if (!isFormValid()) return;
    isApprove ? setIsLoadingForApprove(true) : setIsLoading(true);
    try {
      if (companyId) await submit(companyId, formValues, customer.id);
      onSubmit();
    } catch (e) {
      setIsLoading(false);
      setIsLoadingForApprove(false);
    } finally {
      setIsLoading(false);
      setIsLoadingForApprove(false);
    }
  };

  const customerApprovalHandler = async () => {
    await handleFormSubmit(true);
    dispatch(setModalxComponent("CustomerApproveConfirmPopup"));
    dispatch(setModalxComponentProps({ companyId, customerId: customer.id }));
  };

  const financeSignHandler = async () => {
    dispatch(setModalxComponent("CustomerFinaceSignConfirmPopup"));
    dispatch(setModalxComponentProps({ companyId, customerId: customer.id }));
  };
  const handleOnFileChange = () => {};

  const handleOnClick = (
    customerId: string,
    templateId: string,
    fileId: string
  ) => {
    dispatch(setModalxComponent("FileSignBeforeGenerate"));
    dispatch(
      setModalxComponentProps({ customerId, template: templateId, fileId })
    );
  };

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Tooltip content={t("master_agreement_description")} hasUnderline>
            <Text as="h2" variant="headingSm">
              {t("master_agreement")}&nbsp;
              {customer.isApproved && (
                <Badge tone="success">{t("master_agreement_approved")}</Badge>
              )}
              {customer.isApproved === false && (
                <Badge tone="attention">
                  {t("master_agreement_not_approved")}
                </Badge>
              )}
            </Text>
          </Tooltip>
          {isFinanceFormSent && !isFinanceActive && (
            <Badge progress="incomplete" tone="attention">
              {t("signature_pending")}
            </Badge>
          )}
          {isFinanceActive && (
            <Badge tone="success">{t("finance_activated")}</Badge>
          )}
        </InlineStack>
        {!isFinanceActive &&
          isFinanceFormSent &&
          fileEntity.signType === "Manual" && (
            <ReplaceFile
              title={t("upload_signed_document")}
              onChange={handleOnFileChange}
              subject="customer"
              parentFileId={fileEntity.id}
            />
          )}
        <Form onSubmit={() => handleFormSubmit(false)}>
          <FormLayout>
            {Object.keys(formValues).map((field) => (
              <TextField
                key={field}
                label={t(field)}
                value={String(formValues[field as keyof PostData] ?? "")}
                type={
                  [
                    "advance_payment_percent",
                    "penalty_percent",
                    "non_performance_percent",
                  ].includes(field)
                    ? "number"
                    : "text"
                }
                readOnly={isFinanceActive}
                onChange={(value) =>
                  handleInputChange(field, parseFloat(value) || 0)
                }
                error={errors[field as keyof PostData]}
                requiredIndicator
                autoComplete="off"
              />
            ))}

            {!isApproved ? (
              <InlineStack align="space-between">
                <Button loading={isLoading} disabled={!isFormValid()} submit>
                  {t("save")}
                </Button>
                {!isApproved && (isUserMaster || isUserManager) && (
                  <Button
                    loading={isLoadingForApprove}
                    disabled={!isFormValid()}
                    onClick={customerApprovalHandler}
                  >
                    {t("save_and_approve")}
                  </Button>
                )}
              </InlineStack>
            ) : !isFinanceFormSent ? (
              <Button
                loading={isLoadingForApprove}
                disabled={!isFormValid()}
                onClick={financeSignHandler}
              >
                {t("generate_form_and_send_for_customer")}
              </Button>
            ) : (
              <></>
            )}
          </FormLayout>
        </Form>
        {isFinanceActive && (
          <Card roundedAbove="sm">
            <div className="flex">
              <Text as="h2" variant="headingSm">
                {t("files")}
              </Text>
              <div className="flex-grow"></div>
              <ButtonGroup>
                <UploadFile
                  onChange={handleOnFileChange}
                  subject="customer"
                  subjectId={fileEntity.subjectId}
                  parentFileId={fileEntity.id}
                  categoryId={null}
                  categoryType={fileEntity.category?.type}
                />
                {isFinanceActive && (
                  <Button
                    onClick={() =>
                      handleOnClick(
                        customer.id,
                        fileEntity.templateId?.id,
                        fileEntity.id
                      )
                    }
                  >
                    {t("files_build_pdf")}
                  </Button>
                )}
              </ButtonGroup>
            </div>
            {fileEntity.relatedFiles?.length > 0 ? (
              <Files files={fileEntity.relatedFiles} customerId={customer.id} />
            ) : (
              <EmptyState
                heading={t("no_files")}
                image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
              >
                <UploadFile
                  onChange={handleOnFileChange}
                  subject="customer"
                  subjectId={fileEntity.subjectId}
                  parentFileId={fileEntity.id}
                  categoryId={null}
                  categoryType={fileEntity.category?.type}
                />
              </EmptyState>
            )}
          </Card>
        )}
      </BlockStack>
    </Card>
  );
}

export default CustomerFinanceForm;

function submit(companyId: string, data: PostData, customerId: string) {
  const url = `${API_URL}/${companyId}/customers/${customerId}/finance`;
  return axios.post(url, data).then((response) => response.data);
}
