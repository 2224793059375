import { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "hooks";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import {
  createAddress,
  updateAddress,
  fetchAddressById,
} from "services/address.service";
import Loading from "components/loading/Loading";

function AddressForm({ customer, id }: { customer: string; id?: string }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(
    async (id: string) => {
      setIsLoading(true);
      const data = await fetchAddressById(companyId, customer, id);
      setName(data.name);
      setAddress(data.address);
      setIsLoading(false);
    },
    [companyId, customer]
  );

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
    dispatch(setDestroy());
  };
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  if (!companyId) return null;

  const handleFormSubmit = async () => {
    const formData = {
      id,
      name,
      address
    };
    const action = id ? updateAddress : createAddress;
    try {
      setIsLoading(true);
      await action(formData, customer, companyId);
      dispatch(fetchCustomer({ customerId: customer, companyId, isEdit: true })); //avoid loading state for customer view
      setSuccessMessage("ok");
      setIsLoading(false);
      setTimeout(() => {
        closeModal();
      }, 1000);
    } catch (e) {
      console.error(e);
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  if (isLoading && !name) {
    return <Loading />;
  }


  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("customer_address_name")}
          id="name"
          name="name"
          value={name}
          onChange={setName}
          autoComplete="off"
        />
        <TextField
          label={t("customer_address")}
          id="address"
          name="address"
          value={address}
          onChange={setAddress}
          autoComplete="off"
        />
        <Button
          onClick={handleFormSubmit}
          disabled={!name || !address}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default AddressForm;
