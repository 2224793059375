import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "configureStore";
import { Button, InlineStack, Banner } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { RequestCustomerApproval } from "services/customers.service";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import { setDestroy, setIsModalxOpen } from "components/modalx/modalx.slice";

interface ConfirmModalProps {
  companyId: string;
  customerId: string;
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  companyId,
  customerId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleApproval = async () => {
    try {
      setIsLoading(true);
      const formData = {};
      await RequestCustomerApproval(companyId, customerId, formData);
      dispatch(fetchCustomer({ customerId, companyId: companyId! }));
      setSuccessMessage("ok");
      setIsLoading(false);
      closeModal();
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
    dispatch(setDestroy());
  };

  return (
    <>
      <div className="form-warnings">
        {errMsg && (
          <div>
            <Banner tone="critical">{t(errMsg)}</Banner>
          </div>
        )}
        {successMessage && (
          <div>
            <Banner tone="info">{t(successMessage)}</Banner>
          </div>
        )}
      </div>
      <h1 style={{ textAlign: "center" }}>
        {t("Are_you_sure_you_want_to_approve_customer")}
      </h1>
      <InlineStack align="space-between">
        <Button onClick={handleApproval} loading={isLoading}>
          {t("Yes")}
        </Button>

        <Button onClick={closeModal}>{t("No")}</Button>
      </InlineStack>
    </>
  );
};

export default ConfirmModal;
