import { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
  Checkbox,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchFileCategoryById,
  createFileCategory,
  updateFileCategory,
} from "services/files-category.service";
import { fetchCategoryFilesDetails } from "containers/document/category-files/catFiles.Slice";
import { fetchCategoryDetails } from "containers/document/category-tree/category-tree.Slice";
import { fetchFilesCategories } from "containers/files-categories/files-categories-container.slice";
import { AppDispatch } from "configureStore";

interface AddFilesCategoryFormProps {
  onSubmit?: (result: any) => void;
  id?: string;
  parentId?: string | null;
}

const AddFilesCategoryForm: React.FC<AddFilesCategoryFormProps> = ({
  onSubmit,
  id,
  parentId,
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [subject, setSubject] = useState<string>("customer");
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("external");
  const [isShared, setIsShared] = useState<boolean>(false);
  const [parentCategory, setParentCategory] = useState<string | null>(null);
  const [order, setOrder] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [errMsg, setErrMsg] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(
    async (id: string) => {
      const data = await fetchFileCategoryById(companyId, id);
      setSubject(data.subject);
      setTitle(data.title);
      setParentCategory(data.parentCategory);
      setType(data.type);
      setOrder(data.order);
      setIsShared(data.isShared);
    },
    [companyId]
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id, fetchData]);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      subject,
      title,
      parentCategory: parentId ? parentId : parentCategory,
      type,
      order,
      isShared,
    };
    const action = id ? updateFileCategory : createFileCategory;
    try {
      setIsLoading(true);
      const result = await action(formData, companyId);
      if (companyId) {
        dispatch(fetchCategoryDetails({ companyId }));
        dispatch(fetchFilesCategories(companyId));
        if (id && companyId)
          dispatch(fetchCategoryFilesDetails({ companyId, catId: id }));
      }
      setSuccessMessage("ok");
      if (onSubmit) {
        onSubmit(result);
      }
    } catch (e) {
      console.error(e);
      setErrMsg("error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = useCallback(
    (newChecked: boolean) => setIsShared(newChecked),
    []
  );

  const typeOptions = [
    { label: t("internal"), value: "internal" },
    { label: t("external"), value: "external" },
  ];

  return (
    <Form onSubmit={() => ""}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner>{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner>{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("files_category_title")}
          id="title"
          name="title"
          value={title}
          onChange={(value) => setTitle(value)}
          autoComplete="off"
        />

        <Select
          label={t("type")}
          options={typeOptions}
          onChange={(value) => setType(value)}
          value={type}
        />

        <TextField
          label={t("category_order")}
          id="order"
          name="order"
          value={order}
          onChange={(value) => setOrder(value)}
          autoComplete="off"
        />

        <Checkbox
          label={t("is_shared_category")}
          checked={isShared}
          onChange={handleChange}
        />

        <Button
          onClick={handleFormSubmit}
          disabled={!title || !subject || !type}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default AddFilesCategoryForm;
