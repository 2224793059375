import React, { useState, useCallback } from "react";
import { DropZone, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { setModalxComponent, setDestroy } from "components/modalx/modalx.slice";
import { upload, replaceFile } from "services/upload-file.service";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import { useAppDispatch } from "hooks";

interface ReplaceFileProps {
  title?: string;
  onChange: () => void;
  subject?: string;
  parentFileId?: string;
}

const ReplaceFile: React.FC<ReplaceFileProps> = ({
  title = "upload",
  onChange,
  subject,
  parentFileId = null,
}) => {
  const dispatch = useAppDispatch();
  const { companyId, projectId, customerId } = useParams<{
    companyId: string;
    projectId: string;
    customerId: string;
  }>();

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const { t } = useTranslation();

  const handleDropZoneDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const uploadedFiles: string[] = [];
      try {
        for (const file of acceptedFiles) {
          dispatch(setModalxComponent("Loading"));
          const { data } = await upload(file, companyId);
          uploadedFiles.push(data);
        }

        if (uploadedFiles.length === 1) {
          const payload = {
            prevId: parentFileId,
            nextId: uploadedFiles[0],
            customerId,
          };
          await replaceFile(companyId, payload);
          if (companyId && customerId)
            dispatch(fetchCustomer({ companyId, customerId }));
          dispatch(setDestroy());
        }
      } catch (error) {
        console.error(error);
      }
    },
    [companyId, dispatch, subject, parentFileId, projectId, customerId]
  );

  const toggleOpenFileDialog = useCallback(() => {
    setOpenFileDialog((prev) => !prev);
  }, []);

  return (
    <>
      <Button variant="primary" tone="critical" onClick={toggleOpenFileDialog}>
        {t(title)}
      </Button>
      <div style={{ display: "none" }}>
        <DropZone
          allowMultiple={false}
          openFileDialog={openFileDialog}
          onDropAccepted={handleDropZoneDrop}
          onFileDialogClose={toggleOpenFileDialog}
        >
          <DropZone.FileUpload />
        </DropZone>
      </div>
    </>
  );
};

export default ReplaceFile;
