import axios from "axios";
import { API_URL } from "constants";

// type can be projects,file,invoice etc.
export const createComment = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/comments`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateComment = async (formData, companyId, commentId) => {
  const url = `${API_URL}/${companyId}/comments/${commentId}`;
  return axios.patch(url, formData).then((response) => response.data);
};
