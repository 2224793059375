import { useEffect, useCallback } from "react";
import {
  Layout,
  Page,
  Card,
  InlineGrid,
  Text,
  BlockStack,
  ButtonGroup,
  Badge,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ProjectDetails from "components/project/project-details/project-details";
import UploadFile from "features/upload-file/upload-file";
import Products from "components/customer/products/products";
import Files from "features/files/files";
import "./style.scss";
import CommentsFeature from "components/comment/comments";
import { useSelector, useDispatch } from "react-redux";
import { fetchProjectDetails, setDestroy } from "./project-container.slice";
import { fetchProjectBoard } from "containers/project-board/project-board-container.slice";
import { updateProject } from "services/project.service";
import Loading from "components/loading/Loading";
import { CommentTypes } from "types/Comment";

function ProjectContainer() {
  const { t } = useTranslation();
  const { companyId, projectBoardId, projectId, isLoading } = useParams();
  const dispatch = useDispatch();

  // fetch project by id
  const { projectDetails } = useSelector((state) => state.projectReducer);

  const loadProjectData = useCallback(() => {
    dispatch(fetchProjectDetails({ companyId, projectId }));
  }, [dispatch, companyId, projectId]);

  useEffect(() => {
    loadProjectData();
    return () => {
      dispatch(setDestroy(true));
    };
  }, [loadProjectData]);

  const handleOnFileChange = () => {};

  const updateHandler = async (val, type) => {
    const formData = {
      id: projectId,
      projectBoardColumn:
        type === "project_column" ? val : projectDetails.projectBoardColumn.id,
      name: projectDetails.name,
      description: projectDetails.description,
      user: type === "user" ? val : projectDetails.user.id,
      customer: projectDetails.customer.id,
      order: projectDetails.order,
      products: projectDetails.products,
      isImportant: projectDetails.isImportant,
    };
    try {
      await updateProject(formData, companyId);
      dispatch(fetchProjectBoard(companyId, projectBoardId));
      if (projectId) dispatch(fetchProjectDetails({ companyId, projectId }));
    } catch (e) {
      console.error(e);
    }
  };

  if (isLoading || !projectDetails) {
    return <Loading />;
  }

  return (
    <Page
      fullWidth
      backAction={{
        content: "Project-Board",
        url: `/app/${companyId}/project-boards/${projectBoardId}`,
      }}
      title={`${projectDetails.customer.name} [${projectDetails.customer.externalCode}]`}
      subtitle={
        projectDetails.isImportant && (
          <Badge tone="critical">{t("important")}</Badge>
        )
      }
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("products")}
                </Text>
              </InlineGrid>
              <Products
                products={projectDetails.products}
                isProjectTbl={true}
              />
            </Card>

            <Card roundedAbove="sm">
              <CommentsFeature
                id={projectId}
                CommentTypes={CommentTypes.Projects}
              />
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <ProjectDetails
              id={projectId}
              projectBoardId={projectBoardId}
              name={projectDetails.name}
              description={projectDetails.description}
              projectBoardColumnEntity={projectDetails.projectBoardColumn}
              userEntity={projectDetails.user}
              updateHandler={updateHandler}
            />
            <Card roundedAbove="sm">
              <div className="flex">
                <Text as="h2" variant="headingSm">
                  {t("files")}
                </Text>
                <div className="flex-grow"></div>
                <ButtonGroup>
                  <UploadFile
                    onChange={handleOnFileChange}
                    subject="project"
                    subjectId={projectId}
                  />
                </ButtonGroup>
              </div>
              <Files files={projectDetails.files} />
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default ProjectContainer;
