import axios from "axios";
import { API_URL } from "constants";

export const createFilesTypes = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/files/types`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateFilesTypes = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/files/types/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchFilesTypesById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/files/types/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const fetchFilesTypes = async (companyId) => {
  const url = `${API_URL}/${companyId}/files/types`;
  return axios.get(url).then((response) => response.data);
};
