import { Navigate, Outlet, useParams } from "react-router-dom";
import { useAppSelector } from "hooks";

const ProtectedRoute = ({ roles = [""], ...props }) => {
  const { companyId } = useParams();
  const { role } = useAppSelector((state) => state.authReducer);

  // Check if user roles match allowed roles
  const isAuthorized = roles.some((role_) =>
    role?.permissionsgroups?.includes(role_)
  );
  //role?.permissionsgroups?.includes('Master')
  if (isAuthorized) {
    return <Outlet {...props} />;
  } else {
    return <Navigate to={`/app/${companyId}`} replace />;
  }
};

export default ProtectedRoute;
