import { useEffect, useState } from "react";
import _ from "lodash";
import { Select } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchFilesTypes } from "services/files-types.service";

const FilesTypesSelect = ({
  value,
  onChange,
  isRequired,
}: {
  value?: string;
  onChange?: any;
  isRequired?: boolean;
}) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();

  const handleChange = (selectedValue: string) => {
    onChange(selectedValue);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const collection = companyId ? await fetchFilesTypes(companyId) : [];
        const dataCollection: any = _.map(collection, (row) => ({
          label: row.label,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId]);

  return (
    <div>
      <Select
        label={t("files_types_select")}
        options={collection}
        onChange={handleChange}
        value={value}
        disabled={isLoading}
        requiredIndicator={isRequired ? true : false}
        error={isRequired && !value ? t("file_type_is_required") : ""}
      />
    </div>
  );
};

export default FilesTypesSelect;
