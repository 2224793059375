import axios from "axios";
import { API_URL } from "constants";

export const createAddress = async (formData, customerId, companyId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/addresses`;
  return axios.post(url, formData).then((response) => response.data);
};

export const updateAddress = async (formData, customerId, companyId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/addresses/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
};

export const fetchAddressById = async (companyId, customerId, id) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/addresses/${id}`;
  return axios.get(url).then((response) => response.data);
};

export const deleteAddress = async (id, customerId, companyId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/addresses/${id}`;
  return axios.delete(url).then((response) => response.data);
};

export const fetchAddresses = (companyId, customerId) => {
  const url = `${API_URL}/companies/${companyId}/customers/${customerId}/addresses`;
  return axios.get(url).then((response) => response.data);
};
