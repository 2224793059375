import { createSlice } from "@reduxjs/toolkit";
import { fetch } from "services/user-select.service";

const UsersSlice = createSlice({
  name: "users",
  initialState: {
    usersCollection: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    queryValue: null,
    filtersValue: {},
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setUsers(state, action) {
      state.isLoading = false;
      state.usersCollection = action.payload.usersCollection;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      state.filtersValue[key] = value;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = UsersSlice;

// Extract and export each action creator by name
export const { setLoading, setUsers, setError, setFilterValue } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchUsers =
  (companyId, filtersValue, isUserTechnical) => async (dispatch) => {
    dispatch(setLoading());
    try {
      const { usersCollection } = await fetch(
        companyId,
        filtersValue,
        isUserTechnical
      );
      dispatch(setUsers({ usersCollection }));
    } catch (e) {
      dispatch(setError(""));
    }
  };
